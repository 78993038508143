defineDs('Shared/Framework/Mithril/Scripts/Helpers/Params',
  function () {

    // Functions:
    const detect = (param) => {
      let params = location.search.slice(1);

      if (!params) {
        // Maybe URL is constucted with hash, e.g. /roed-konto/mine-oplysninger#/cards?foo&bar.
        const hasParamsInHash = location.hash?.split('?');

        if (hasParamsInHash?.length > 1) {
          params = hasParamsInHash[1];
        }
      }

      if (params) {
        const arr = params.split('&');
        return arr.includes(param);
      }
    };

    var extract = function (param, includeHashParams) {
      var params = location.search ? location.search.slice(1) : '';

      if (typeof includeHashParams === 'undefined' || includeHashParams === true) {
        var hashParams = location.hash.indexOf('?') > -1 ? location.hash.split('?').pop() : '';
        params += '&' + hashParams;
      }

      var regex = new RegExp(param, 'g');

      if (regex.test(params)) {
        var segments = params.split('&');

        for (var i = 0; i < segments.length; i++) {
          var segment = segments[i];
          var elements = segment.split('&');

          if (elements[0] === param) {
            return elements[1];
          }
        }

        return params.replace(/=/g, '').split(param)[1].split('&')[0];
      }
    };

    var set = function (params) {
      var newUrl = setOnUrl(window.location.href, params);

      history.replaceState(null, document.title, newUrl);
    };

    var setOnUrl = function (url, params) {

      var hashParts = url.split('#');
      var hash = hashParts[1];
      var queryParts = hashParts[0].split('?');

      var query;

      if (queryParts.length == 0) {
        // We might have fucked up and set the query parameters as part of the hash. Check for that
        query = hash.split('?')[1];
      } else {
        query = queryParts[1];
      }

      var pathname = queryParts[0];

      if (typeof params !== 'string') {

        var parameterMap = query ? query.split('&').reduce(function (parameterMap, parameter) {
          var parts = parameter.split('=');
          var key = decodeURIComponent(parts[0]);
          var value = decodeURIComponent(parts[1]);
          parameterMap[key] = value;
          return parameterMap;
        }, {}) : {};

        Object.keys(params).forEach(function (key) {
          parameterMap[key] = params[key];
        });

        params = Object.keys(parameterMap).map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(parameterMap[key]);
        }).join('&');
      }

      var newUrl = pathname + '?' + params + (hash ? '#' + hash : '');

      return newUrl;
    };

    // Public functions:
    return {
      detect,
      extract: extract,
      set: set,
      setOnUrl: setOnUrl
    };

  });
