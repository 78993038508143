defineDs('Common/Framework/EventHandling/Scripts/OutsideClick', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {
  // Variables:
  var added = [];
  var removed = [];

  // Functions:
  var add = function ($element, context, callback) {
    var exists = false;

    if (typeof callback === 'undefined') {
      callback = context;
    } else {
      context.onunload = function () {
        removed.push($element);
      };
    }

    for (var i = 0; i < added.length; i++) {
      if (added[i].$element === $element) {
        exists = true;

        break;
      }
    }

    if (!exists) {
      added.push({ $element: $element, callback: callback });
    }
  };

  var handler = function (event) {
    var $elementClicked = event.target;
    var subscribers = [];
    var countCallbacks = 0;

    for (var i = 0; i < added.length; i++) {
      var subscriber = added[i];
      var $element = subscriber.$element;
      var hasBeenRemoved = false;

      for (var j = 0; j < removed.length; j++) {
        if ($element === removed[j]) {
          hasBeenRemoved = true;
        }
      }

      if (!hasBeenRemoved) {
        subscribers.push(subscriber);

        var $parentNode = $elementClicked;
        var match = subscriber.$element === $elementClicked;

        while (typeof $parentNode !== 'undefined' && $parentNode !== null && !match) {
          $parentNode = $parentNode.parentNode;

          match = $element === $parentNode;
        }

        if (!match) {
          subscriber.callback(event);
          countCallbacks++;
        }
      }
    }

    if (countCallbacks > 0) {
      // If one or more matches, then always end by doing Mithril redraw
      m.redraw();
    }

    added = subscribers;
    removed = [];
  };

  // Init:
  if ('ontouchstart' in window) {
    ['click', 'touchstart'].forEach(function (type) {
      document.body.addEventListener(type, handler);
    });
  } else {
    document.body.addEventListener('click', handler);
  }

  // Public functions:
  return add;

});
