defineDs('DanskeSpil/Domain/Authentification/Scripts/LoginAdapter', [], function () {

  const logoutPath = DS.Config.DSLOGINPREFIX + '/logud';
  const logoutUrl = (DS.Config.LOGINPAGE_HOST || '') + logoutPath;

  const backgroundLogout = (callback) => {
    console.log('LoginAdapter logout start');

    // create iframe and insert in body
    const iframe = document.createElement('iframe');
    iframe.addEventListener('load', () => {
      console.log('LoginAdapter logout iframe loaded');
      callback();
    });
    iframe.setAttribute('style', 'display:none;');
    iframe.setAttribute('src', logoutUrl);
    document.body.appendChild(iframe);

    // fetch(logoutUrl).then(() => {
    //  console.log('LoginAdapter logout ended');
    //
    // }, () => {
    //  console.log('LoginAdapter logout failed');
    //  callback();
    // });
  };

  return {
    backgroundLogout
  };

});
