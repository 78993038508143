defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Helpers/Debounce', [], () => {
  /**
   * Creates a debounced function that delays invoking `func` until after `wait` milliseconds
   * have elapsed since the last time the debounced function was invoked. Optionally, `func`
   * can be invoked immediately on the leading edge instead of the trailing edge of the wait interval.
   *
   * @param {Function} func - The function to debounce.
   * @param {number} wait - The number of milliseconds to delay.
   * @param {boolean} [immediate=false] - If `true`, trigger the function on the leading edge, instead of the trailing.
   * @returns {Function} - Returns the new debounced function.
   */
  return (func, wait, immediate) => {
    let timeout;

    return function () {
      const context = this;
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };
});
