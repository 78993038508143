defineDs('Shared/Framework/Mithril/Scripts/Helpers/Storage', function () {

  // Variables:
  var DEFAULT_TTL = 14 * 24 * 60 * 60; // 14 days
  var HARD_RESET_LOCALSTORAGE_TTL = 90 * 24 * 60 * 60; // 90 days

  var storageFallback = {};

  // Functions:
  var prefix = function (skipContext) {
    return (DS.Config.ENV || 'ds') + '-' + (skipContext ? 'DS' : DS.Config.CONTEXT) + '-';
  };

  var supported = function () {
    try {
      localStorage.getItem(prefix() + 'storage-check');
      localStorage.setItem(prefix() + 'storage-check', 1);

      return true;
    } catch (e) { }

    return false;
  }();

  var isTtlExpired = function (ttlVal) {
    return ttlVal && ttlVal < (new Date().getTime() / 1000);
  };

  var set = function (key, val, ttl, moreOptions) {
    var skipContext = moreOptions && moreOptions.skipContext;
    var fullKey = prefix(skipContext) + key;
    var ttlKey = prefix(skipContext) + 'ttl-' + key;
    var ttlVal = Math.floor(new Date().getTime() / 1000) + (typeof ttl === 'number' ? ttl : DEFAULT_TTL);

    // Supported:
    if (supported) {
      if (typeof val === 'undefined' || val === null) {
        localStorage.removeItem(fullKey);
        localStorage.removeItem(ttlKey);
      } else {
        localStorage.setItem(fullKey, val);

        if (ttlVal) {
          localStorage.setItem(ttlKey, ttlVal);
        } else {
          localStorage.removeItem(ttlKey);
        }
      }

      // Use fallback implementation:
    } else {
      if (typeof val === 'undefined' || val === null) {
        delete storageFallback[fullKey];
        delete storageFallback[ttlKey];
      } else {
        storageFallback[fullKey] = val;

        if (ttlVal) {
          storageFallback[ttlKey] = ttlVal;
        } else {
          delete storageFallback[ttlKey];
        }
      }
    }
  };

  var get = function (key, moreOptions) {
    var skipContext = moreOptions && moreOptions.skipContext;

    var fullKey = prefix(skipContext) + key;
    var ttlKey = prefix(skipContext) + 'ttl-' + key;
    var val = null;
    var ttl = null;

    // Supported:
    if (supported) {
      val = localStorage.getItem(fullKey);
      ttl = localStorage.getItem(ttlKey);

      // Use fallback implementation:
    } else {
      val = storageFallback[fullKey];
      ttl = storageFallback[ttlKey];
    }

    if (val) {
      var ttlVal = null;

      try {
        ttlVal = parseInt(ttl, 10);
      } catch (e) { }

      // Within cache limit:
      if (!isTtlExpired(ttlVal)) {
        return val;

        // Cleanup (expired):
      } else {
        set(key, null);
      }
    }

    return null;
  };

  var cleanupExpiredItems = function () {
    try {
      var keys = Object.keys(localStorage);
      var ttlPrefix = prefix() + 'ttl-';

      for (var i = 0; i < keys.length; i++) {
        var ttlKey = keys[i];

        if (ttlKey.indexOf(ttlPrefix) === 0) {
          try {
            var ttl = localStorage.getItem(ttlKey);
            var ttlVal = parseInt(ttl, 10);

            if (isTtlExpired(ttlVal)) {
              var key = prefix() + ttlKey.substr(ttlPrefix.length);
              console.warn('Deleting from localStorage: ' + key + ', ' + ttlKey);
              localStorage.removeItem(key);
              localStorage.removeItem(ttlKey);
            }

          } catch (e) { }

        }
      }

      set('StorageCleaned', '1', 60 * 10);

    } catch (e) { }
  };

  // INIT:

  if (supported) {

    if (!get('StorageCreatedAt')) {
      set('StorageCreatedAt', (new Date()).getTime(), 365 * 24 * 60 * 60);
    } else {
      var diff = ((new Date()).getTime() - parseInt(get('StorageCreatedAt'), 10)) / 1000;
      if (diff > HARD_RESET_LOCALSTORAGE_TTL) {
        console.warn('Clearing localStorage (HARD_RESET_LOCALSTORAGE_TTL reached)');
        localStorage.clear();
      }
    }

    if (get('StorageCleaned') !== '1') {
      // Only run cleanup max once per 10 minute
      cleanupExpiredItems();
    }

  }

  // Exports
  return {
    supported: supported,
    set: set,
    get: get
  };

});
