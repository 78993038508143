defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Components/NavigationScroll', [], function () {
  const navigation = document.querySelector('.js-navigation');

  if (!navigation) {
    return;
  }

  const safeZoneTop = 250; // Always show Navigation when scrolling on the page is less than this amount in pixels.
  let isNavigationVisible = true;
  let previousScrollY = 0;
  let currentScrollY = window.scrollY;

  const showNavigation = (toggle) => {
    document.dispatchEvent(new CustomEvent(toggle ? 'navigation:casino:show' : 'navigation:casino:hide'));
    navigation.classList.toggle('ds-navigation--scroll-up', toggle);
    navigation.classList.toggle('ds-navigation--scroll-down', !toggle);

    if (isNavigationVisible !== toggle) {
      isNavigationVisible = toggle;

      if (!isNavigationVisible) {
        // close any open main menu submenus
        document.dispatchEvent(new CustomEvent('navigation:mainMenu:closeAllSubMenus'));
      }
    }
  };

  const handleScroll = () => {
    currentScrollY = window.scrollY;

    if (currentScrollY < safeZoneTop) {
      showNavigation(true);
      return;
    }

    const userScrollsUpwards = currentScrollY < previousScrollY;
    showNavigation(userScrollsUpwards);
    previousScrollY = currentScrollY;
  };

  const initialize = () => {
    document.addEventListener('navigation:scroll', handleScroll);
    document.addEventListener('navigation:scroll:show', () => showNavigation(true));

    currentScrollY = window.scrollY;

    setInterval(() => {
      if (!isNavigationVisible && window.scrollY < safeZoneTop) {
        showNavigation(true);
      }
    }, 1000);

  };

  initialize();

});


