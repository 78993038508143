
defineDs('Shared/Framework/Mithril/Scripts/Helpers/IOSUtils', function () {

  // Variables:
  var measureRuler = null;

  // Functions:

  var isIOS = function () {
    return document.body.className.indexOf('ios-device') > -1;
  };

  var isLandscape = function () {
    return (window.innerWidth > window.innerHeight);
  };


  var meassureInnerHeight = function () {
    // Inspiration/Credit: https://github.com/tylerjpeterson/ios-inner-height/blob/master/dist/ios-inner-height.js

    if (!measureRuler) {
      measureRuler = document.createElement('div');
      measureRuler.style.position = 'fixed';
      measureRuler.style.height = '100vh';
      measureRuler.style.width = 0;
      measureRuler.style.top = '-100vh';
      measureRuler.style.minHeight = '-webkit-fill-available';
      document.body.appendChild(measureRuler);
    }

    var w = Math.abs(window.orientation) === 90 ? measureRuler.offsetHeight : window.innerWidth;
    var h = Math.abs(window.orientation) === 90 ? window.innerWidth : measureRuler.offsetHeight;

    if (Math.abs(window.orientation) !== 90) {
      return h;
    }

    return w;
  };

  var isFullscreen = function () {
    // This definition of full screen is "as much full screen as possible"
    // on iPhone portrait mode, you cannot get totally rid of address bar.
    // But when the user scrolls down, the adress bar gets thinner. In this case, this function will return true.

    return window.innerHeight == meassureInnerHeight();
  };

  var initFixDeviceClasses = function () {

    // Credit: https://51degrees.com/blog/missing-ipad-tablet-web-traffic

    // Returns the portion of the User-Agent which represents the family of
    // Apple products the device belongs.
    // @return iPhone, iPad or Macintosh otherwise empty string
    function getFamily() {
      var segments = /iPhone|iPad|Macintosh/.exec(navigator.userAgent);
      if (segments && segments.length > 0) {
        return segments[0];
      }
    }

    // Try getting the renderer string via the conventional debug
    // extension.
    // @return the UNMASKED_RENDERER_WEBGL parameter value.
    function getReportedRenderer() {
      var canvas = document.createElement('canvas');
      if (canvas != null) {
        var context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (context) {
          var info = context.getExtension('WEBGL_debug_renderer_info');
          if (info) {
            return context.getParameter(info.UNMASKED_RENDERER_WEBGL);
          }
        }
      }
    }

    // Returns the Apple device in a form that will match Google Analytics
    // device type settings.
    // @return Tablet, Mobile, Desktop or Unknown.
    function getAppleDeviceType() {
      var family = getFamily();

      // If User-Agent reports Macintosh double check this against the
      // graphics renderer.
      if (family == 'Macintosh') {
        var renderer = getReportedRenderer();

        // Sizes from: https://developer.apple.com/library/archive/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/Displays/Displays.html
        var  possibileIpadWidth = isLandscape() ? [1366, 834, 1024] : [1024, 1112, 768];
        if (renderer.includes('Apple') && possibileIpadWidth.indexOf(screen.width) > -1) {
          family = 'iPad';
        }

        // Older iPads.
        if (/iPad/.test(navigator.platform)) {
          family = 'iPad';
        }

        // New iPads.
        if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) {
          family = 'iPad';
        }
      }

      // Map the Apple product family to the Google device type.
      switch (family) {
      case 'iPad': return 'tablet';
      case 'iPhone': return 'mobile';
      case 'Macintosh': return 'desktop';
      default: return 'unknown';
      }
    }

    // Get the device type.
    var deviceType = getAppleDeviceType();

    // Return if non-Apple
    if (deviceType === 'unknown') {
      return;
    }

    // Add ios-device class if missing
    if (deviceType !== 'desktop' && document.body.className.indexOf('ios-device') > -1) {
      document.body.className += ' ios-device';
    }

    // If wrong serverside-detected, clean up:
    if (deviceType === 'tablet' && document.body.className.indexOf('is-tablet-detected') === -1) {
      document.body.className += ' is-tablet-detected';
      document.body.classList.remove('is-desktop-detected');
      document.body.classList.remove('is-mobile-detected');
    }

    if (deviceType === 'mobile' && document.body.className.indexOf('is-mobile-detected') === -1) {
      document.body.className += ' is-mobile-detected';
      document.body.classList.remove('is-desktop-detected');
      document.body.classList.remove('is-tablet-detected');
    }

  };

  initFixDeviceClasses();


  return {
    isIOS: isIOS,
    isLandscape: isLandscape,
    innerHeight: innerHeight,
    isFullscreen: isFullscreen
  };

});
