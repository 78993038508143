defineDs('Shared/Framework/Mithril/Scripts/Helpers/Picture', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril'
], function (m) {

  this.breakpoints = {
    xxs: 375,
    xs: 480,
    sm: 768,
    md: 950,
    lg: 1024,
    xl: 1216,
    xxl: 1440
  };

  this.addedBreakpoints = [];
  this.previousOutput = m.prop();

  this.output = function () {
    if (this.previousOutput()) return this.previousOutput();

    var validSources = this.sources
      .filter(function (source) {
        return !!source[0] && source[1] >= 0;
      });

    if (validSources.length === 0) return null;

    var largestSource = validSources.reduce(function (acc, curr) {
      if (curr[1] >= acc[1]) {
        acc = curr;
      }
      return acc;
    }, ['', 0])[0];

    if (!largestSource) return null;

    var highToLowSources = validSources.sort(function (a, b) {
      return b[1] - a[1];
    });

    this.previousOutput(m('picture', { class: this.cssClass || 'picture' },
      [
        highToLowSources.map(function (source) {
          return m('source', { srcset: source[0], media: '(min-width:' + source[1] + 'px)' });
        }),
        m('img', { src: largestSource, alt: this.altText || '' })
      ]
    ));

    return this.previousOutput();
  }.bind(this);

  var addSource = function (source, breakpoint) {
    if (this.addedBreakpoints.indexOf(breakpoint) > -1) return methods;
    this.addedBreakpoints.push(breakpoint);
    this.sources = this.sources || [];
    this.sources.push([source, breakpoint]);
    return methods;
  }.bind(this);

  this.alt = function (alt) {
    if (this.altText) return methods;
    this.altText = alt;
    return methods;
  }.bind(this);

  this.source = function (source, breakpoint) {
    return addSource(source, breakpoint);
  }.bind(this);

  this.mobile = function (source) {
    return addSource(source, 0);
  }.bind(this);

  this.xxs = function (source) {
    return addSource(source, this.breakpoints.xxs);
  }.bind(this);

  this.xs = function (source) {
    return addSource(source, this.breakpoints.xs);
  }.bind(this);

  this.sm = function (source) {
    return addSource(source, this.breakpoints.sm);
  }.bind(this);

  this.md = function (source) {
    return addSource(source, this.breakpoints.md);
  }.bind(this);

  this.lg = function (source) {
    return addSource(source, this.breakpoints.lg);
  }.bind(this);

  this.xl = function (source) {
    return addSource(source, this.breakpoints.xl);
  }.bind(this);

  this.xxl = function (source) {
    return addSource(source, this.breakpoints.xxl);
  }.bind(this);

  this.css = function () {
    if (this.cssClass) return methods;
    var res = [];
    for (var i = 0; i < arguments.length; i++) {
      res.push(arguments[i]);
    }
    this.cssClass = res.join(' ');
    return methods;
  }.bind(this);

  this.reset = function () {
    this.addedBreakpoints = [];
    this.sources = [];
    this.previousOutput(null);
    return methods;
  }.bind(this);

  var methods = {
    alt: this.alt,
    css: this.css,
    output: this.output,
    source: this.source,
    mobile: this.mobile,
    xxs: this.xxs,
    xs: this.xs,
    sm: this.sm,
    md: this.md,
    lg: this.lg,
    xl: this.xl,
    xxl: this.xxl,
    reset: this.reset,
  };

  return methods;
});
