defineDs('Common/Framework/ResourceHandling/Scripts/LazyLoad', function () {

  var lazyloadImages;

  var lazyLoad = function () {
    var lazyloadThrottleTimeout;
    var lazyloadImages = Array.prototype.slice.call(document.querySelectorAll('.lazy'));

    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      var scrollTop = window.pageYOffset;
      lazyloadImages.forEach(function (img) {
        if (img.offsetTop < (window.innerHeight + scrollTop)) {

          if (img.tagName.toLowerCase() === 'source') {
            img.srcset = img.dataset.srcset;
          } else {
            img.src = img.dataset.src;
          }

          img.classList.remove('lazy');
        }
      });
      if (lazyloadImages.length === 0) {
        document.removeEventListener('scroll', lazyLoad);
        window.removeEventListener('resize', lazyLoad);
        window.removeEventListener('orientationChange', lazyLoad);
      }
    }, 20);
  };

  document.addEventListener('DOMContentLoaded', function () {
    if ('IntersectionObserver' in window) { // IntersectionObserver not supported in IE 11, Safari 9.1 etc
      lazyloadImages = Array.prototype.slice.call(document.querySelectorAll('.lazy'));
      var imageObserver = new IntersectionObserver(function (entries, ignoreObserver) { // eslint-disable-line compat/compat
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var image = entry.target;

            if (image.tagName.toLowerCase() === 'source') {
              image.srcset = image.dataset.srcset;
            } else {
              image.src = image.dataset.src;
            }

            image.classList.remove('lazy');
            imageObserver.unobserve(image);
          }
        });
      });

      lazyloadImages.forEach(function (image) {
        imageObserver.observe(image);
      });
    } else {
      lazyLoad();
      document.addEventListener('scroll', lazyLoad);
      window.addEventListener('resize', lazyLoad);
      window.addEventListener('orientationChange', lazyLoad);
    }
  });
});
