defineDs('Common/Framework/EventHandling/Scripts/Event', function () {

  /**
   * This differs from CrossWindowEvents.js as this does not persist to localStorage and subscribe to events
   * across tabs/windows. Use this when sharing state between components on the same page.
   */
  var subscribers = [];

  var fire = function (name, data) {
    console.log('Event.fire(' + name + ')', data);
    var message = { name: name, data: data };

    dispatchEvent(message); // fire the message locally
  };

  var subscribe = function (name, callback) {
    console.log('Event.subscribe(' + name + ')');
    subscribers.push({ name: name, callback: callback });
  };

  var dispatchEvent = function (message) {
    console.log('Event.subscribers.dispatchEvent()', message);
    console.log('Event.subscribers (all event types):', subscribers);

    subscribers.forEach(function (item) {
      if (message.name === item.name) {
        try {
          item.callback(message.data);
        } catch (err) { // error in external code should not break the loop
          console.error('Exception catched in Event.subscribers.dispatchEvent() thrown in listener callback:', err);
        }
      }
    });

    // Needed for the new React part to be able to subscribe to the same events
    const customEvent = new CustomEvent(`${message.name}`, { detail: message.data });
    document.dispatchEvent(customEvent);
  };

  const unsubscribe = (name, callback) => {
    console.log('Event.unsubscribe(' + name + ')');

    // Filter out the subscriber with the specified name and callback
    subscribers = subscribers.filter((item) => {
      return !(item.name === name && item.callback === callback);
    });
  };

  return {
    fire: fire,
    subscribe: subscribe,
    unsubscribe,
  };

});
