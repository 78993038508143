defineDs('DanskeSpil/Domain/PermissionPopUp/Scripts/Components/PermissionPopUpSpot',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'Common/Framework/EventHandling/Scripts/AppEvent',
    'DanskeSpil/Domain/PermissionPopUp/Scripts/Helpers/NotificationsApi',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],

  function (Component, Render, Storage, AppEvent, NotificationsApi, Ensighten) {

    // Variables:
    var render = Render();

    // Component:
    Component('PermissionPopUp', function (m, route, settings) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.$content = m.prop();
          this.$shadow = m.prop();
          this.$wrapper = m.prop();
          this.$page = m.prop();
          this.$confirm = m.prop();
          this.loading = m.prop();
          this.eloquaData = m.prop();
          this.dismissed = m.prop(true);
          this.PermissionPopUpData = m.prop(null);
          this.storageName = m.prop('');
          this.atConfirm = m.prop(false);

          // Functions:
          this.closeWindow = function (event) {
            var self = this;
            Storage.set(self.storageName(), true);
            AppEvent.fire('PermissionPopUp-close');
            this.dismissed(true);
            if (event.currentTarget.attributes.dataTracking) {
              Ensighten.pushGaEvent('permission', event.currentTarget.attributes.datatracking.value, settings?.name);
            }
          }.bind(this);

          this.showConfirm = function () {
            var self = this;
            self.loading('true');
            self.setAccept();
            Storage.set(self.storageName(), true);
            self.loading('false');
            Ensighten.pushGaEvent('permission', event.currentTarget.attributes.datatracking.value, settings?.name);
          }.bind(this);

          this.setAccept = function () {
            this.eloquaData().data.EmailPermission = true;
            this.eloquaData().data.referenceId = settings?.name;
            NotificationsApi.updateSettings(this.eloquaData().data).then(function (response) {
              if (response.Success) {
                this.atConfirm(true);
              } else {
                this.closeWindow();
              }
            }.bind(this));
          }.bind(this);

          this.kvnNotVisible = function () {
            var $kvn = document.querySelector('.operations-messenger-content');
            return $kvn ? !getComputedStyle($kvn).display === 'none' : true;
          };

          this.storageName(settings.id);
          var storageValue = Storage.get(this.storageName()) || false;
          // Context:
          if (location.search.toLowerCase().indexOf('skipPermissionPopUp') === -1) {
            if (settings) {
              this.PermissionPopUpData(settings);
              NotificationsApi.getSettings().then(function (response) {
                if (response.status === 'success') {
                  this.eloquaData(response);
                }

                if (this.eloquaData() && !this.eloquaData().data.EmailPermission && !storageValue) {
                  this.dismissed(false);
                  Ensighten.pushGaEvent('permission', 'popup_shown', settings.name);
                }
                m.redraw();
              }.bind(this));
            }
          }

          render.ready.then(function (nodes) {
            var $content = nodes['content'];
            var $shadow = nodes['shadow'];
            var $wrapper = nodes['wrapper'];
            var $page = nodes['page'];
            var $confirm = nodes['confirm'];

            if ($content && $content.$element) {
              this.$content($content.$element);
            }

            if ($shadow && $shadow.$element) {
              this.$shadow($shadow.$element);
            }

            if ($wrapper && $wrapper.$element) {
              this.$wrapper($wrapper.$element);
            }

            if ($page && $page.$element) {
              this.$page($page.$element);
            }

            if ($confirm && $confirm.$element) {
              this.$confirm($confirm.$element);
            }

          }.bind(this));

        },
        view: function (controller) {

          // Variables:
          var data = controller.PermissionPopUpData();

          // View:
          if (data && controller.kvnNotVisible()) {
            return m('div', { class: 'ppu__wrapper ' + (controller.dismissed() ? 'ppu_wrapper--hide' : 'ppu_wrapper--show'), config: render.depend('wrapper') }, [
              m('div', { class: 'ppu__shadow', dataTracking: 'popup_closed_background', config: render.depend('shadow'), onclick: controller.closeWindow }),
              m('div', { class: 'ppu__content', config: render.depend('content'), style: 'background-color: ' + data.backgroundColor }, [

                m('div', { class: 'ppu__element ' + (controller.atConfirm() ? 'ppu__element--hide' : 'ppu__element--show'), config: render.depend('page'), onmouseover: controller.mouseover }, [
                  m('img.ppu__closebutton', { dataTracking: 'popup_closed_cross', onclick: controller.closeWindow, src: '/Components/DanskeSpil/Domain/PermissionPopUp/Graphics/SpriteSheets/icons.svg#close_use' }),
                  // content
                  m('div', m.trust(data.content)),
                  // button 1
                  m('div', { class: 'ppu__cta-overflow ppu__cta-overflow--left', style: 'border-color: ' + data.button1BorderColor }, [
                    m('a', { href: '#', class: 'ppu__cta', style: 'background-color: ' + data.button1Color, dataTracking: 'popup_closed_no', onclick: controller.closeWindow }, [
                      m('div', { class: 'ppu__cta-container' }, [
                        m.trust(data.button1Content),
                      ]),
                    ]),
                  ]),
                  // button 2
                  m('div', { class: 'ppu__cta-overflow ppu__cta-overflow--right', style: 'border-color: ' + data.button2BorderColor }, [
                    m('a', { href: '#', class: 'ppu__cta', style: 'background-color: ' + data.button2Color, dataTracking: 'popup_closed_yes', onclick: controller.showConfirm }, [
                      m('div', { class: 'ppu__cta-container' }, [
                        (controller.loading()) ?
                          m('div', { class: 'ppu__cta-loader-box' }, [
                            m('span', { class: 'loader-animation-box' }, [
                              m('span', { class: 'loader-dot' }),
                              m('span', { class: 'loader-dot' }),
                              m('span', { class: 'loader-dot' })
                            ])
                          ]) : null,
                        m.trust(data.button2Content),
                      ])
                    ]),
                  ]),
                ]),
                // Confirm window
                m('div', { class: 'ppu__element ' + (!controller.atConfirm() ? 'ppu__element--hide' : 'ppu__element--show'), config: render.depend('confirm'), onmouseover: controller.mouseover }, [
                  m('img.ppu__closebutton', { onclick: controller.closeWindow, src: '/Components/DanskeSpil/Domain/PermissionPopUp/Graphics/SpriteSheets/icons.svg#close_use' }),
                  // content
                  m('div', m.trust(data.confirmContent)),
                  // button 1
                  m('div', { class: 'ppu__cta-overflow', style: 'border-color: ' + data.confirmButtonBorderColor }, [
                    m('a', { href: '#', class: 'ppu__cta', style: 'background-color: ' + data.confirmButtonColor, onclick: controller.closeWindow }, [
                      m('div', { class: 'ppu__cta-container' }, [
                        m.trust(data.confirmButtonContent),
                      ])
                    ])
                  ])
                ])
              ])
            ]);
          }
        }

      };

      // Routes:
      route('/', root);

    });

  });
