defineDs('Shared/Framework/Mithril/Scripts/Helpers/ApiRequest', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Common/Framework/ErrorHandling/Scripts/ErrorLogging'
], function (m, Utils, Storage, ErrorLogging) {

  // Global variables:
  var requestsInProgress = [];
  var isBeforeUnloadFired = false;
  var isUnloadFired = false;

  // Helper listeners, for debugging if Request is logged after pageunload (then we might mute them)
  window.addEventListener('beforeunload', function () {
    console.debug('ApiRequest - beforeunload event fired');
    isBeforeUnloadFired = true;
  });
  window.addEventListener('unload', function () {
    console.debug('ApiRequest - unload event fired');
    isUnloadFired = true;
  });

  // Helper:
  var ApiRequest = function (options) {

    // Variables:
    var config = options.config;
    var csrfRequestMethods = ['POST', 'PUT', 'DELETE'];
    var cacheableMethods = ['GET'];
    var deferred = m.deferred();
    var preventRejection = false;
    var startTime = (new Date().getTime());
    var xhrReference = null;

    // OPTIONS:
    options = options || {};
    options.background = typeof options.background !== 'undefined' ? options.background : true;
    options.method = options.method || 'GET';
    var methodIsCachable = cacheableMethods.indexOf(options.method) > -1;
    options.customErrors = (typeof options.customErrors === 'number' ? [options.customErrors] : options.customErrors) || [];

    // noCache option: Should we  add ?noCache=(unixtimestamp) to the URL (default enabled for GET requests)
    options.noCache = typeof options.noCache !== 'undefined' ? options.noCache : methodIsCachable;

    // avoidSimultaneous option: Should we skip this request,
    //   if another request with same url / cacheKey is in progress(default for GET requests)

    options.avoidSimultaneous = typeof options.avoidSimultaneous !== 'undefined' ? options.avoidSimultaneous : methodIsCachable;

    options.origin = options.origin || false;
    options.timeout = options.timeout || 25; // In seconds
    var shouldHaveCsrf = csrfRequestMethods.indexOf(options.method) > -1;
    options.withCSRF = typeof options.withCSRF !== 'undefined' ? options.withCSRF : shouldHaveCsrf;

    // Calculate duration:
    var calculateDuration = function () {
      return (new Date().getTime()) - startTime;
    };

    // Filter away URL in request list, used after a promise resolves etc.
    var removeItemFromRequestList = function (cacheKey) {
      requestsInProgress = requestsInProgress.filter(function (requestInList) {
        return requestInList.cacheKey !== cacheKey;
      });
    };

    // Sentry track error:
    var sentryLog = function (error, xhr) {
      // Return if logging disabled
      if (options.logging === false) {
        return;
      }

      // Return if statuscode id 422 or if error is CLIENT.REFUSED or CLIENT.TIMEOUT
      if (xhr && xhr.status === 422) {
        return;
      }

      // Return if timeout
      if (xhr && xhr.readyState === 4 && xhr.status === 0) {
        return;
      }

      try {
        xhr = xhr || {};

        var errorMessage = (error && error.errorMessage) || '';

        var filteredUrl = options.url.split('?')[0].split('/').map(function (segment, index) {
          if (segment === null || segment === '') return segment;

          var lowerSegment = segment.toLowerCase();

          if (lowerSegment === 'dli' || lowerSegment === 'dlo') return '<DLIO>';
          if (!isNaN(segment, 10)) return '<NO>';
          if (/^[0-9a-fA-F]{32}$/.test(segment)) return '<UUID32bytes>';
          if (/^[0-9a-fA-F]{16}$/.test(segment)) return '<UUID16bytes>';

          // Making the last parts generic/merged into same Sentry events, eg:
          // /<DLIO>/scapi/danskespil/playeraccountmanagement/playergames/<NO>/<NO>/<NO>/alle-spil/alle-kanaler
          // ---> /<DLIO>/scapi/danskespil/playeraccountmanagement/playergames/<NO>/<NO>/<NO>/<CHUNK>/<CHUNK>
          if (index >= 7) return '<CHUNK>';

          return segment;
        }).join('/');

        var message = 'ApiRequest: ' + options.method + ' ' + filteredUrl + (xhr.status ? ' [' + xhr.status + ']' : '') + (errorMessage ? ' (' + errorMessage + ')' : '');

        var server = '';
        try {
          server = xhr && xhr.getResponseHeader ? xhr.getResponseHeader('X-DS-Env') : '';
        } catch (ignoreError) {
          server = 'EXCEPTION_REACHED';
        }

        ErrorLogging.capture(message, {
          extra: {
            csrf: options.withCSRF ? Utils.cookie(DS.Config.CONTEXT + 'CSRF') : 'disabled',
            data: options.data,
            duration: calculateDuration(),
            error: error || xhr.statusText,
            response: xhr.response,
            server: server,
            status: xhr.status,
            readyState: xhr.readyState,
            type: options.method,
            origin: options.origin,
            url: options.url,
            isBeforeUnloadFired: isBeforeUnloadFired,
            isUnloadFired: isUnloadFired
          },
          level: 'warning',
          tags: { custom: 'ApiRequest' }
        });
      } catch (error) {
        console.warn('Sentry logging failed');
      }
    };

    // Setup:
    options.config = function (xhr) {
      xhr.timeout = options.timeout * 1000;

      if (options.withCredentials) {
        xhr.withCredentials = true;
      }

      if (options.withCSRF) {
        var csrfToken = Utils.cookie(DS.Config.CONTEXT + 'CSRF');

        if (csrfToken) {
          xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
        }

        if (typeof options.headers === 'object') {
          Object.keys(options.headers).forEach(function (key) {
            var value = options.headers[key];
            xhr.setRequestHeader(key, value);
          });
        }
      }

      xhr.onerror = function (error) {
        xhr.abort();

        if (!preventRejection) {
          sentryLog({ errorMessage: 'CLIENT.UNKNOWN_ERROR' }, xhrReference);

          deferred.reject(error);

          preventRejection = true;
        }
      };

      // Reject with custom errors, if connection timeout or refused:
      xhr.addEventListener('readystatechange', function () {
        if (xhr.readyState === 4 && (xhr.status === 0 || options.customErrors.includes(xhr.status))) {

          if (!preventRejection) {
            sentryLog({ errorMessage: null }, xhrReference);

            deferred.reject({ errorMessage: null });

            preventRejection = true;
          }
        }
      });

      if (config) {
        config(xhr);
      }

      // Store XHR reference for sentry logging:
      xhrReference = xhr;
    };


    // If options.requireAuthCookie is set, then check SSO cookies and reject without starting request if they arent set:
    if (options.requireAuthCookie === true && !Utils.cookie(DS.Config.CONTEXT + 'SitecoreIsLoggedIn')) {
      // sentryLog({ errorMessage: 'CLIENT.REQUIRE_AUTH_COOKIE_FAILED' }, xhrReference); // Dont log this anymore
      deferred.reject();
      return deferred.promise;
    }


    // Cache key:
    var cacheKey = options.method + ',' + options.url;
    if (options.data && typeof options.data === 'object') {
      cacheKey += ',' + m.route.buildQueryString(options.data);
    }

    // Look into cache, if localCacheTTL (in seconds) is given:
    var localCacheKey = 'ApiRequestCache/' + cacheKey;
    var useLocalCaching = methodIsCachable && (typeof options.localCacheTTL !== 'undefined' && options.localCacheTTL > 0);

    if (useLocalCaching) {
      var cacheJson = null;
      var cacheVal = Storage.get(localCacheKey);

      if (cacheVal) {
        try {
          cacheJson = JSON.parse(cacheVal);
        } catch (e) { }
      }

      if (cacheJson) {
        deferred.resolve(cacheJson);

        return deferred.promise;
      }
    }

    // Add new request to a list of requests to avoid sequential requests to the same URL:
    if (options.avoidSimultaneous) {
      var requestIfInList = requestsInProgress.filter(function (requestInList) {
        return requestInList.cacheKey === cacheKey;
      });

      if (requestIfInList.length === 0) {
        requestsInProgress.push({
          cacheKey: cacheKey,
          url: options.url,
          deferred: deferred
        });
      } else {
        return requestIfInList[0].deferred.promise;
      }
    }

    // Add noCache-param on GET requests, only on the MSIE (because they cache to much):
    var msIE = (window.navigator.userAgent.match(/MSIE|Trident/) !== null);
    if (msIE && options.noCache && options.url && options.url.indexOf('noCache=') === -1) {
      options.url += (options.url.indexOf('?') > -1 ? '&' : '?') + 'noCache=' + new Date().getTime();
    }

    m.request(options).then(function (response) {
      if (response && useLocalCaching) {
        Storage.set(localCacheKey, JSON.stringify(response), options.localCacheTTL);
      }

      deferred.resolve(response);

      removeItemFromRequestList(cacheKey);
    }, function (error) {

      if (!preventRejection) {
        sentryLog(error, xhrReference);

        deferred.reject(error);

        preventRejection = true;
      }

      removeItemFromRequestList(cacheKey);
    });

    // Return:
    return deferred.promise;

  };

  // Public functions:
  return ApiRequest;

});
