defineDs('DanskeSpil/Domain/Header/Scripts/HeaderTracking', [
  'Shared/Framework/Ensighten/Scripts/Ensighten',
], function (Ensighten) {

  const trackBrandMenu = () => {
    const links = document.querySelectorAll('.brand-nav .brand-nav__link');
    links.forEach((link) => {
      if (link.href === '#') return;
      const label = link.innerText || '';
      link.addEventListener('click', () => {
        Ensighten.pushGaEventOnPageRedirect('navigation', 'brand_navigation', label);
      });
    });
  };

  const trackBrandSubMenu = () => {
    const links = document.querySelectorAll('.brand-nav .sub-navigation__link');
    links.forEach((link) => {
      if (link.href === '#') return;
      let parentLabel = link.closest('.brand-nav__item')?.querySelector('.brand-nav__link')?.innerText || '';
      if (parentLabel) parentLabel += ' > ';
      const label = link.innerText || '';
      link.addEventListener('click', () => {
        Ensighten.pushGaEventOnPageRedirect('navigation', 'brand_sub_navigation', parentLabel + label);
      });
    });
  };

  const trackBrandSubSubMenu = () => {
    const links = document.querySelectorAll('.brand-nav .sub-navigation .mega-menu__link-list__item');
    links.forEach((link) => {
      if (link.href === '#') return;
      let parentLabel = link.closest('.brand-nav__item')?.querySelector('.brand-nav__link')?.innerText || '';
      if (parentLabel) parentLabel += ' > ';
      let titleLabel = link.closest('.sub-sub-navigation')?.querySelector('.mega-menu__section__title .sub-sub-navigation__section-link')?.innerText || '';
      if (titleLabel) titleLabel += ' > ';
      const label = link.innerText || '';
      link.addEventListener('click', () => {
        Ensighten.pushGaEventOnPageRedirect('navigation', 'brand_sub_navigation', parentLabel + titleLabel + label);
      });
    });
  };

  const trackCorporateMenu = () => {
    const links = document.querySelectorAll('.corporate-nav .corporate-nav__logo a, .corporate-nav .corporate-nav__list-link:not(.js-link)');
    links.forEach((link) => {
      if (link.href === '#') return;
      const label = link.innerText || link.querySelector('img')?.alt || '';
      link.addEventListener('click', () => {
        Ensighten.pushGaEventOnPageRedirect('navigation', 'corporate_navigation', label);
      });
    });
  };

  const trackCorporateSubMenu = () => {
    const links = document.querySelectorAll('.corporate-nav .games .games__list-item-link');
    links.forEach((link) => {
      if (link.href === '#') return;
      const label = link.querySelector('img').alt || link.href || '';
      link.addEventListener('click', () => {
        Ensighten.pushGaEventOnPageRedirect('navigation', 'corporate_sub_navigation', label);
      });
    });
  };

  const initialize = () => {
    trackBrandMenu();
    trackBrandSubMenu();
    trackBrandSubSubMenu();
    trackCorporateMenu();
    trackCorporateSubMenu();
  };

  initialize();

});
