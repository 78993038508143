defineDs('DanskeSpil/Domain/Authentification/Scripts/LoginSSO', [
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Common/Framework/ErrorHandling/Scripts/ErrorLogging'
], function (Storage, ErrorLogging) {

  var JSONP_LOGOUT_CALLBACKS = {
    BACK_TO: DS.Config.BASE_HOST + '/Components/DanskeSpil/Project/Design/Js/Jsonp/ds.jsonp_logoutsuccess.js',
    ON_ERROR: DS.Config.BASE_HOST + '/Components/DanskeSpil/Project/Design/Js/Jsonp/ds.jsonp_logouterror.js'
  };

  var _logoutJsonpCallback = null;

  var handleJSONPLogoutSuccess = function () {
    /* This method is fired from ds.jsonp_logoutsuccess.js */
    if (_logoutJsonpCallback) {
      _logoutJsonpCallback();
      _logoutJsonpCallback = null;
    }
  };


  var executeJSONP = function (url, callback) {
    url = url + (url.indexOf('?') === -1 ? '?' : '&') + '_=' + (new Date().getTime());

    if (callback) {
      var callbackName;

      if (typeof callback === 'string') {
        callbackName = callback;
      } else {
        if (!window.DsJsonpCallbacks) {
          window.DsJsonpCallbacks = {};
        }

        // generate callback name
        var i = 0;
        while (typeof window.DsJsonpCallbacks['c' + i] !== 'undefined') i++;
        // setup callback
        window.DsJsonpCallbacks['c' + i] = callback;
        callbackName = 'DsJsonpCallbacks.c' + i;
      }

      url = url + '&callback=' + callbackName;
    }

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  };


  var relay = function (callback) {
    if (!callback) {
      console.error('LoginSSO.relay: No callback given');
      return;
    }

    executeJSONP(DS.Config.SSO_SERVER_URL + '/bosso/sso/json/relay.do', function (json) {
      if (typeof json === 'string') {
        json = JSON.parse(json);
      }
      callback(json);
    });
  };

  var logout = function (callback) {
    // Circuit breaker/Throttle: Limit logout requests to max one per 3sec.
    if (Storage.get('LogoutCircuitBreakerActive') === '1') {
      console.trace('LoginSSO.logout');
      ErrorLogging.capture('LoginSSO.logout circuit breaker reached', {
        level: 'warning',
        tags: { custom: 'MultipleLogout' }
      });
      return;
    }

    Storage.set('LogoutCircuitBreakerActive', '1', 3); // Activate CircuitBreaker for 3 sec

    // Set callback
    _logoutJsonpCallback = callback;

    // Logout sso
    executeJSONP(DS.Config.SSO_SERVER_URL + '/bosso/sso/logout.do?josso_cmd=logout&josso_back_to=' + JSONP_LOGOUT_CALLBACKS.BACK_TO + '&josso_on_error=' + JSONP_LOGOUT_CALLBACKS.ON_ERROR);
  };

  // public functions
  var publc = {
    logout: logout,
    handleJSONPLogoutSuccess: handleJSONPLogoutSuccess,
    relay: relay
  };
  return publc;
});

