/*
 * DS Frontend Integration API (development version)
 * This file must be included from a loader-script where DSAPI.config should be setup.
 */

(function () {
  if (!window.sitecoreEnv) {
    var API = window.DSAPI; // for better minifying


    /* ### HELPERS ### */

    /* Debug method - using console.log */
    var debug = function () {
      try {
        if (API.config && API.config.debug && window.console && console.log) {
          var lines = [].slice.call(arguments);
          lines.unshift('DSAPI debug log:');
          if (console.log && console.log.apply) {
            console.log.apply(console, lines);
          }
        }
      } catch (e) {
      }
    };

    var checkAttributesPresent = function (params, attributesList) {
      for (var i = 0; i < attributesList.length; i++) {
        if (typeof params[(attributesList[i])] === 'undefined') return false;
      }
      return true;
    };

    var checkDocumentDomain = function () {
      return document.domain == 'danskespil.dk';
    };

    /* ### PRE INIT ### */
    /* Set document domain to danskespil.dk */
    if (!checkDocumentDomain()) {
      try {
        document.domain = 'danskespil.dk';
      } catch (e) {
        debug('Error: Could not set domain to danskespil.dk. DSAPI might not work well.');
      }
    }

    /* Check if config is ready */
    if (!API || !API.config || !checkAttributesPresent(API.config, ['gamesServer', 'gameVertical', 'integrationMethod'])) {
      debug('Error: No valid DSAPI config. Aborted initialization of DSAPI!');
    }

    /* Setup DS window and parent class */
    var win = null;
    if (API.config.integrationMethod == 'iframe') {

      win = (function () {
        var checkWindow = function (w) {
          return (typeof w.DS !== 'undefined' && typeof w.DS.apiParent !== 'undefined');
        };

        /* First, try top window */
        try {
          if (checkWindow(window.top)) {
            return window.top;
          }
        } catch (err) {
        }

        /* Next, try walk up to through window hiearchy */
        var w = window.parent;
        while (w) {
          try {
            // found DS window, return it
            if (checkWindow(w)) {
              return w;
            }

            // reached top of hiearchy
            if (!w.parent || w.parent == w) {
              return null;
            }

            w = w.parent;
          } catch (err) {
            // could not find DS window or no access to it
            return null;
          }
        }

        return null;
      })();

    } else if (API.config.integrationMethod == 'mainframe') {
      win = window;
    }

    var dsParent = win && win.DS && win.DS.apiParent;
    if (!dsParent && (API.config.objects.Account || API.config.objects.ParentFrame)) {
      debug('Fatal error: Parent window or apiParent not found (needed for Account and ParentFrame objects)!');
      return;
    }

    /* #####################
       * ###   DataLayer   ###
       * ##################### */
    API.pushToDataLayer = function (e) {
      if (dsParent) {
        dsParent.pushToDataLayer(e);
      } else if (window.dataLayer) {
        window.dataLayer.push(e);
      } else {
        debug('Could not add to dataLayer', e);
      }
    };

    API.SizmekTrack = function (id) {
      if (!id) {
        return;
      }
      var img = document.createElement('img');
      img.height = 1;
      img.src = (document.location.protocol == 'https:' ? 'https' : 'http') + ('://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&ActivityID={0}').replace('{0}', id);
      document.body.appendChild(img);
    };

    /* #####################
         * ### ACCOUNT CLASS ###
         * ##################### */
    if (API.config.objects.Account) {

      API.Account = (function () {
        var jsonpCallbacks = {};

        var _userLoggedOutCallbacks = [];
        var _userLoggedOutCallbacksOnce = [];
        var onUserLoggedOut = function () {
          debug('DSAPI onUserLoggedOut - running callbacks');
          runCallbackCue(_userLoggedOutCallbacks, _userLoggedOutCallbacksOnce);
        };

        var _userLoggedInCallbacks = [];
        var _userLoggedInCallbacksOnce = [];
        var onUserLoggedIn = function () {
          debug('DSAPI onUserLoggedIn - running callbacks');
          runCallbackCue(_userLoggedInCallbacks, _userLoggedInCallbacksOnce);
        };

        var _balanceUpdatedCallbacks = [];
        var onBalanceUpdated = function () {
          debug('DSAPI onBalanceUpdated - running callbacks');
          runCallbackCue(_balanceUpdatedCallbacks, null);
        };

        /* Helper */

        var sortCallbacks = function (callbacks) {
          /* This sorts the callback array - Low order value, means run callback first */
          callbacks.sort(function (a, b) {
            return (a[1] > b[1]) ? 1 : -1;
          });
        };

        var callbackCatch = function (func) {
          if (typeof func === 'function') {
            debug('Run callback: ' + func);
            try {
              func();
            } catch (err) { // error in external code should not break the loop
              if (typeof console.error !== 'undefined') {
                console.error('Exception catched in DSAPI.runCallbackCue() thrown in listener callback');
                console.error(err);
              }
            }
          }
        };

        var runCallbackCue = function (callbacks, callbacksOnce) {
          if (callbacks) {
            if (callbacks.length > 1) {
              sortCallbacks(callbacks);
            }

            for (var i = 0; i < callbacks.length; i++) {
              var c = callbacks[i][0];
              callbackCatch(c);
            }
          }

          if (callbacksOnce) {
            while (callbacksOnce.length > 0) {
              var cc = callbacksOnce.shift();
              callbackCatch(cc);
            }
          }
        };

        var executeJSONP = function (url, callback) {
          // generate callback name
          var i = 0;
          while (typeof jsonpCallbacks['c' + i] !== 'undefined') i++;
          // setup callback
          jsonpCallbacks['c' + i] = callback;

          url = url + '?callback=DSAPI.Account.jsonpCallbacks.c' + i + '&_=' + (new Date().getTime());

          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = url;
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(script, s);
        };

        var init = function () {
          dsParent.subscribeUserLoggedIn(onUserLoggedIn);
          dsParent.subscribeUserLoggedOut(onUserLoggedOut);
          dsParent.subscribeBalanceUpdated(onBalanceUpdated);
        };

        var openLogin = function (params) {
          debug('DSAPI.Account.openLogin - params: ', params);
          return dsParent.openLogin(params);
        };

        var logout = function (params) {
          debug('DSAPI.Account.logout - params: ', params);
          dsParent.logout(params);
        };

        var doBalanceUpdate = function (params) {
          debug('DSAPI.Account.doBalanceUpdate - params: ', params);
          dsParent.doBalanceUpdate(params);
        };

        var subscribeUserLoggedIn = function (params) {
          var prio = params.prio || 5;
          debug('DSAPI.Account.subscribeUserLoggedIn - params: ', params);
          if (params && typeof params.callback === 'function') {
            _userLoggedInCallbacks.push([params.callback, prio]);
          }
        };

        var subscribeUserLoggedOut = function (params) {
          var prio = params.prio || 5;
          debug('DSAPI.Account.subscribeUserLoggedOut - params: ', params);
          if (params && typeof params.callback === 'function') {
            _userLoggedOutCallbacks.push([params.callback, prio]);
          }
        };

        var subscribeBalanceUpdated = function (params) {
          var prio = params.prio || 5;
          debug('DSAPI.Account.subscribeBalanceUpdated - params: ', params);
          if (params && typeof params.callback === 'function') {
            _balanceUpdatedCallbacks.push([params.callback, prio]);
          }

        };

        var openMyPage = function (params) {
          debug('DSAPI.Account.openMyPage (DEPRECATED) - params: ', params);
        };

        var openPayment = function (params) {
          debug('DSAPI.Account.openPayment - params: ', params);
          dsParent.openPayment(params);
        };

        var noUserAlias = function (params) {
          debug('DSAPI.Account.noUserAlias - params: ', params);
          dsParent.noUserAlias(params);
        };

        var getBalance = function (params) {
          debug('DSAPI.Account.getBalance - params: ', params);
          dsParent.getBalance(params);
        };

        var relaySSO = function (params) {
          debug('DSAPI.Account.relaySSO - params: ', params);
          if (params && typeof params.callback === 'function') {
            // Do the BW SSO relay request, to get the assertionId for Partner auth service
            executeJSONP(API.config.gamesServer + '/bosso/sso/json/relay.do', function (data) {
              var assertionId = data.assertionId || null;
              debug('Got response from relaySSO. assertionId: ' + assertionId);
              params.callback(assertionId);
            });
          }
        };

        var getLoginStatus = function (params) {
          debug('DSAPI.Account.getLoginStatus - params: ', params);

          if (params && typeof params.callback === 'function') {
            // Do the BW SSO check
            executeJSONP(API.config.gamesServer + '/bosso/sso/json/checkActiveSession.do', function (data) {
              var loggedIn = (data && data.isSessionActive && data.isSessionActive == 'true') || false;
              debug('Got response from getLoginStatus. loggedIn: ' + loggedIn);
              params.callback(loggedIn);
            });
          }
        };

        /* Public methods */
        return {
          init: init,
          jsonpCallbacks: jsonpCallbacks,
          openLogin: openLogin,
          logout: logout,
          doBalanceUpdate: doBalanceUpdate,
          subscribeUserLoggedOut: subscribeUserLoggedOut,
          subscribeUserLoggedIn: subscribeUserLoggedIn,
          subscribeBalanceUpdated: subscribeBalanceUpdated,
          openMyPage: openMyPage,
          openPayment: openPayment,
          noUserAlias: noUserAlias,
          getBalance: getBalance,
          relaySSO: relaySSO,
          getLoginStatus: getLoginStatus
        };

      })();
    }


    /* ######################
       * ### TRACKING CLASS ###
       * ###################### */

    if (API.config.objects.Tracking) {
      API.Tracking = (function () {

        /* Get queryParam helper */
        var getQueryParam = function (key, queryString) {
          queryString = queryString || location.search.substring(1);
          var params = queryString.split('&');
          for (var i = 0; i < params.length; i++) {
            var args = params[i].split('=');
            if (args[0] == key) {
              return args[1];
            }
          }
          return null;
        };

        var init = function () {
          /* If btag query param is given, store in btag cookie */
          var btag = getQueryParam('btag') || getQueryParam('btag', location.hash.slice(1).replace('?', '&'));
          if (btag) {
            var date = new Date();
            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
            document.cookie = 'btag=' + encodeURIComponent(btag) + '; secure; domain=danskespil.dk; expires=' + date.toGMTString() + '; path=/';
          }
        };

        var trackPageview = function (params) {
          debug('DSAPI.Tracking.trackPageview (DEPRECATED) - params: ', params);

          // For legacy/backwards-compability. If a callback is defined, fire it:
          if (typeof params.callback === 'function') {
            params.callback();
          }

        };

        // Depricated methods (Here till thay are not used in the api anymore)
        var trackEcommerce = function (params) {
          debug('DSAPI.Tracking.trackEcommerce (DEPRECATED) - params: ', params);
        };
        var trackEvent = function (params) {
          debug('DSAPI.Tracking.trackEvent (DEPRECATED) - params: ', params);
        };
        var trackSocial = function () {
          debug('DSAPI.Tracking.trackSocial (DEPRECATED) stump');
        };

        /* Public methods */
        return {
          init: init,
          trackEvent: trackEvent,
          trackPageview: trackPageview,
          trackEcommerce: trackEcommerce,
          trackSocial: trackSocial
        };
      })();
    }

    /* #########################
       * ### PARENTFRAME CLASS ###
       * ######################### */
    if (API.config.objects.ParentFrame) {
      API.ParentFrame = (function () {

        var setParentBackground = function (params) {
          debug('DSAPI.ParentFrame.setParentBackground (DEPRECATED) - params: ', params);
        };

        var updateGameFrameHeight = function (params) {
          if (API.config.integrationMethod != 'iframe') return;

          debug('DSAPI.ParentFrame.updateGameFrameHeight');
          dsParent.updateGameFrameHeight(params);
        };

        var setParentHash = function (params) {
          debug('DSAPI.ParentFrame.setParentHash');
          if (!checkAttributesPresent(params, ['hash'])) {
            debug('DSAPI.ParentFrame.setParentHash: Aborted. Not enough required params');
            return;
          }

          dsParent.setParentHash(params);
        };

        var subscribeParentHashChanged = function (params) {
          debug('DSAPI.ParentFrame.subscribeParentHashChanged');
          if (!checkAttributesPresent(params, ['callback'])) {
            debug('DSAPI.ParentFrame.subscribeParentHashChanged: Aborted. Not enough required params');
            return;
          }

          dsParent.subscribeParentHashChanged(params);

        };

        var setParentUrl = function (url) {
          if (dsParent) {
            dsParent.setUrl(url);
          }
        };


        /* Public methods */
        return {
          setParentBackground: setParentBackground,
          updateGameFrameHeight: updateGameFrameHeight,
          setParentHash: setParentHash,
          subscribeParentHashChanged: subscribeParentHashChanged,
          setParentUrl: setParentUrl
        };

      })();
    }


    /* ### POST INIT ### */

    if (API.config.integrationMethod == 'iframe' && API.ParentFrame) {
      var onLoad = function () {
        /* Set height on load */
        API.ParentFrame.updateGameFrameHeight();
      };
      if (/loaded|complete/.test(document.readyState)) onLoad(); // onload already passed, we just fire the function right away
      else if (window.addEventListener) window.addEventListener('load', onLoad, false);
      else window.attachEvent('onload', onLoad);
    }

    /* Account */
    if (API.Account) {
      API.Account.init();
    }

    /* Tracking */
    if (API.Tracking) {
      API.Tracking.init();
    }


    /* Ready queue */
    API.loaded = true;
    console.log('queue', API.readyQueue);
    /* Run the readyQueue */
    if (API.readyQueue.length > 0) {
      while (API.readyQueue.length > 0) {
        var c = API.readyQueue.shift();
        if (c) {
          c();
        }
      }
    }
    /* Override the DSAPI.ready() function - we don't wanna queue anymore from here */
    API.ready = function (callback) {
      callback();
    };

    debug('DSAPI code loaded');
  }
})();
