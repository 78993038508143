defineDs('DanskeSpil/Project/Design/Js/Global/HashChange', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Common/Framework/EventHandling/Scripts/Event'
], function (Utils, Event) {

  // Variables:
  var _oldHash = '';
  var _routes = [];
  var _hashSource = '';
  var _restoreHashOnClear = ''; // for Openbet
  var _gameVerticalHashMatcher = null;

  var removeAppendingHash = function (hash) {
    return (hash && hash.indexOf('#') === 0) ? hash.slice(1) : hash;
  };

  var clearHash = function (source) {
    source = source || '';

    if (source == 'hashchange' || removeAppendingHash(location.hash) != removeAppendingHash(_restoreHashOnClear)) {
      // not already cleared
      location.hash = _restoreHashOnClear;
      // _restoreHashOnClear = '';
      if (source != 'closeOverlay') { // Prevent loops from closeOverlay
        Event.fire('overlay-hash-cleared');
      }
    }
  };

  var getGameVerticalHashMatcher = function () {
    return _gameVerticalHashMatcher;
  };

  var setGameVerticalHashMatcher = function (func) {
    _gameVerticalHashMatcher = func;
  };

  var setHashFromDsapi = function (hash) {
    // This is called, when OB tries to set a OB hash
    console.debug('setHashFromDsapi', hash);

    // Only continue if this is a game vertical hash
    if (_gameVerticalHashMatcher && _gameVerticalHashMatcher(hash)) {
      _hashSource = 'dsapi';
      _restoreHashOnClear = hash;

      // if current hash is  blank or it is a game vertical hash, apply the new game vertical hash
      if (removeAppendingHash(location.hash) == '' || _gameVerticalHashMatcher(location.hash)) {
        var newUrl = location.href.split('#')[0] + '#' + hash;
        window.history.replaceState(null, null, newUrl);
      }

    }
  };

  var match = function (pattern, callback) {
    _routes.push({ pattern: pattern, callback: callback });
  };

  var setupRoutes = function () {

    /* Hash cleared */
    match('', function () {
      clearHash('hashchange');
    });

    /* Mapping from legacy #minside links to new PAM */
    match(/^minside\//, function (hash) {
      Utils.logDeprecated('HashChange.js - ' + hash);

      if (location.pathname.length == 0) {
        // Dont run on frontpage
        return;
      }

      var ref = hash.substring(8);
      var pageMap = {
        profil: '/mine-oplysninger/', // logDeprecated: Still in use per 6/2-2020, 15-03-2022
        forside: '/mine-oplysninger/', // logDeprecated: Still in use per 13/8-2019, 15-03-2022
        'aktive-spil': { // logDeprecated: Still in use per 6/2-2020, 15-03-2022
          url: '/mine-spil',
          hash: '/1/365/aktive/alle-kanaler/'
        },
        konto: '/saldo/' // logDeprecated: Still in use per 6/2-2020, 15-03-2022
      };

      var newPathname;
      var url;

      if (typeof pageMap[ref] === 'string') {
        var hasSearch = window.location.search.slice(1).length > 0;
        var newSearch = (hasSearch ? location.search + '&' : '?') + 'returnUrl=' + window.location.href.split('#')[0];

        newPathname = DS.Config.PRETTYPREFIX + pageMap[ref];
        url = window.location.href.split(window.location.pathname)[0] + newPathname + newSearch;

      } else if (typeof pageMap[ref] === 'object') {
        var newHash = pageMap[ref].hash;
        newPathname = DS.Config.PRETTYPREFIX + pageMap[ref].url;
        url = window.location.href.split(window.location.pathname)[0] + newPathname + '#' + newHash + newSearch;

      }

      if (url) {
        // Delay 200ms to be sure we have Sentry tracked
        setTimeout(function () {
          window.location.href = url;
        }, 200);
      }

    });

    /* If we switch from DS hash to a game vertical hash, close DS overlays */
    match(function (hash) {
      return _gameVerticalHashMatcher && _gameVerticalHashMatcher(hash) && !_gameVerticalHashMatcher(_oldHash);
    }, function () {
      /* Close DS overlays if any open */
      Event.fire('overlay-hash-cleared');
    });

  };

  var routeHandler = function (ignoreChangeCheck) {
    /* If not ignoreChangeCheck, then only continue if hash is changed */
    if (!ignoreChangeCheck && _oldHash === location.hash) {
      return;
    }

    console.debug('RouteHandler: hash = ' + location.hash);

    /* Remove appending # and do lowercase */
    var hash = removeAppendingHash(location.hash);
    var lowerHash = hash.toLowerCase();

    /* Some custom Ensighten tracking  - overlays */
    if (lowerHash.indexOf('p/') === 0) {
      var path = lowerHash.substring(2);
      var type = path.indexOf('/quick/nyhed/') !== -1 ? 'newsPage' : 'aboutHowToPage';
      var name = path.substring(path.lastIndexOf('/') + 1);

      window.dataLayer.push({
        event: 'virtualPage',
        page: {
          virtualPath: '/virtualpath/' + path,
          name: name,
          type: type
        }
      });

    }


    /* Calculate rules */
    for (var i = 0; i < _routes.length; i++) {
      var route = _routes[i];
      var matched = false;

      if (route.pattern instanceof RegExp) {
        matched = route.pattern.test(lowerHash);
      }
      if (typeof route.pattern === 'string') {
        matched = (route.pattern.toLowerCase() === lowerHash);
      }
      if (typeof route.pattern === 'function') {
        matched = (route.pattern(hash) === true);
      }

      if (matched) {
        route.callback(hash);
        break; // stops the iteration
      }

    }

    // Fire DSAPI on parent hash changed events
    if (_hashSource != 'dsapi') {
      Event.fire('dsapi-on-parent-hash-changed');
    }

    _oldHash = location.hash;
    _hashSource = '';
  };

  var init = function () {
    setupRoutes();

    /* ### INITIALIZE THE HASH CHANGE EVENT ### */
    // The browser supports the hashchange event: attach our routeHandler to this event listener
    window.addEventListener('hashchange', routeHandler, false);

    // run handler at page load
    routeHandler(false);
  };

  // Wrap in DOMContentLoaded (earlier it was $.ready), to ensure routes set by other modules have higher priority than the ones in this module
  document.addEventListener('DOMContentLoaded', function () {
    init();
  });


  // Public functions:
  return {
    clearHash: clearHash,
    getGameVerticalHashMatcher: getGameVerticalHashMatcher,
    setGameVerticalHashMatcher: setGameVerticalHashMatcher,
    match: match,
    setHashFromDsapi: setHashFromDsapi
  };

});
