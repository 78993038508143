defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Components/NavigationChooseGames', [], function () {
  const menu = document.querySelector('.js-navigation-choose-games');

  if (!menu) {
    return;
  }

  const openMenuToggle = document.querySelector('.js-navigation-open-choose-games');
  const closeMenuToggle = menu.querySelector('.js-navigation-close-choose-games');

  const openMenu = () => {
    document.dispatchEvent(new CustomEvent('navigation:menu:open', { detail: { menu } }));
  };

  const closeMenu = () => {
    document.dispatchEvent(new CustomEvent('navigation:menu:close', { detail: { menu } }));
  };

  const initialize = () => {
    // Add event listeners
    openMenuToggle?.addEventListener('click', openMenu);
    closeMenuToggle?.addEventListener('click', closeMenu);
    document.addEventListener('navigation:chooseGames:close', closeMenu);
  };

  initialize();

});
