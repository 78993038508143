/* Include DSAPI (hosted on another DS server) that is used by some of the Game Vertical integrations */

(function () {

  /* Load only if not in sitecore and no existing DSAPI object found */

  if (!window.sitecoreEnv && !window.DSAPI) {

    /* The intended way to load DSAPI is by including an external loader script dsapi_loader.jsp
      In order to get best performance, we skip this, and just insert the loader script right here: */

    window.DSAPI = {

      config: {
        gamesServer: DS.Config.SSO_SERVER_URL,
        debug: DS.Config.DSAPI_TESTENV,
        testEnv: DS.Config.DSAPI_TESTENV,

        gameVertical: 'GenericSitecore',
        channel: 'web',
        integrationMethod: 'mainframe',

        objects: {
          Account: true,
          Tracking: true,
          ParentFrame: true
        }
      },
      loaded: false,

      readyQueue: (window.DSAPI && window.DSAPI.readyQueue) || [],
      ready: function (c) { window.DSAPI.readyQueue.push(c); }
    };

  }
})();
