defineDs('DanskeSpil/Domain/Footer/Scripts/Footer', [
  'DanskeSpil/Framework/TimeUtils/Scripts/Clock'
], function (Clock) {
  const footer = document.querySelector('.js-global-footer');

  if (!footer) {
    return;
  }

  const removeFooter = () => {
    footer.remove();
  };

  const initialize = () => {
    Clock.init();

    document.addEventListener('footer:remove', removeFooter);
  };

  initialize();
});
