defineDs('DanskeSpil/Domain/Header/Scripts/Components/TimeConsumingNotification', [
  'Shared/Framework/Mithril/Scripts/Core/Component',
  'Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
  'Shared/Framework/Mithril/Scripts/Helpers/MithrilRedrawEverySecond',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
],
function (Component, Dictionary, MithrilRedrawEverySecond, Utils, Event, TimeUtils, LoginCache) {
  var TimeConsumingNotificationDictionary = new Dictionary('/TimeConsumingNotification');

  Component('time-consuming-notification', [TimeConsumingNotificationDictionary], function (m, route, settings) {

    var $parentElement = this.$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

    var root = {
      controller: function () {
        var fixMinSec = function (i) {
          return (parseInt(i, 10) < 10) ? '0' + i : i;
        };

        this.thisInstanceIsFallback = m.prop($parentElement.classList.contains('time-consuming-notification-fallback') || $parentElement.getAttribute('data-fallback') === 'true'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        this.headerText = m.prop(m.trust(TimeConsumingNotificationDictionary.get(Utils.isMobile() ? 'HeaderTextWithoutLinks' : 'HeaderText')));

        this.getClockValue = function () {
          var now = TimeUtils.getCurrentDateTime();
          return fixMinSec(now.getHours()) + ':' + fixMinSec(now.getMinutes());
        }.bind(this);

        this.getTickerValue = function () {
          var userObj = LoginCache.getUserObj();
          var loginDate = new Date(userObj.customerInfo.loginAt);
          var datediff = TimeUtils.timeDifferenceInSeconds(loginDate, TimeUtils.getServerDateTime());
          var compoundTimeObject = TimeUtils.getCompoundTimeObject(1000 * datediff);
          var hours = (compoundTimeObject.days * 24) + compoundTimeObject.hours;
          return fixMinSec(hours) + ':' + fixMinSec(compoundTimeObject.mins) + ':' + fixMinSec(compoundTimeObject.secs);

        }.bind(this);

        this.getShowClock = function () {
          return settings.TimeConsumingType === 'showonlyclock' || !LoginCache.isLoggedIn();
        };

        this.channelDisabled = function () {
          var channel = Utils.isMobile() ? 'mobile' : (Utils.isTabletDevice() ? 'tablet' : 'desktop');
          if (settings.TimeConsumingChannels && settings.TimeConsumingChannels.indexOf(channel) === -1) {
            return true;
          }
          return false;
        };

        this.useFallback = function () {
          if (document.getElementsByClassName('is-mobile-detected').length > 0
            && document.getElementsByClassName('region-avalon-bingo-gvc').length === 1
            && document.getElementsByClassName('gamelauncher-iframe-outer-wrapper').length === 1) {
            return true;
          }

          if (
            (document.querySelector('header.top-navigation') !== null || document.getElementById('pam-header-nav'))
            && document.getElementsByClassName('livecasino-game-spot-iframe-outer-wrapper maximized').length === 0
            && document.getElementsByClassName('livecasino-game-spot-iframe-outer-wrapper fullscreen').length === 0
            && document.getElementsByClassName('gamelauncher-iframe-outer-wrapper maximized').length === 0
            && document.getElementsByClassName('gamelauncher-iframe-outer-wrapper fullscreen').length === 0) {

            return false;
          }

          return true;
        }.bind(this);

        this.firstRender = (ignore$element, isInitialized) => {
          if (isInitialized) return;

          Event.fire('header-area-height-changed');
        };

        this.hideBar = function () {
          if (this.channelDisabled()) {
            return true;
          }

          if (this.thisInstanceIsFallback() != this.useFallback()) {
            return true;
          }

          return false;
        }.bind(this);

        if (this.channelDisabled()) {
          // Add special body class, if channel is not supported
          document.body.classList.add('time-consuming-notification-channel-disabled');
        } else {
          // Start the timer
          MithrilRedrawEverySecond.start();
        }
      },
      view: function (ctrl) {
        if (ctrl.hideBar()) {
          return;
        }

        var showClock = ctrl.getShowClock();

        return m('div', { class: 'time-consuming-notification' + (ctrl.thisInstanceIsFallback() ? ' fallback' : ''), config: ctrl.firstRender },
          m('div', { class: 'time-consuming-notification__content' }, [

            m('a', { href: 'https://spillemyndigheden.dk', target: '_new', class: 'time-consuming-notification__link' },
              m('svg', { class: 'time-consuming-notification__icon' },
                m('use', { href: '/Components/DanskeSpil/Domain/Footer/Graphics/SpriteSheets/FooterIcons.svg#sm-logo' })
              )
            ),

            m('div', { class: 'time-consuming-notification__header-text time-consuming-notification__link' }, ctrl.headerText()),

            (showClock ?
              // Clock:
              [
                m('div', { class: 'time-consuming-notification__timer' },
                  m('div', { class: 'time-consuming-notification__timer-item time-consuming-notification__clock' }, ctrl.getClockValue())
                ),
                m('svg', { class: 'time-consuming-notification__timer-icon' }, m('use', { href: '/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#icon_clock' }))
              ]
              :
              // Ticker:
              [
                m('div', { class: 'time-consuming-notification__timer' },
                  m('div', { class: 'time-consuming-notification__ticker' }, ctrl.getTickerValue())
                ),
                m('svg', { class: 'time-consuming-notification__timer-icon time-consuming-notification__stopwatch' }, m('use', { href: '/Components/DanskeSpil/Domain/Header/Graphics/SpriteSheets/Icons.svg#icon_stopwatch' }))
              ]
            )


          ])
        );
      }
    };
    route('/', root);
  });
});
