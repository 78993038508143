defineDs('DanskeSpil/Domain/Onboarding/Scripts/Components/OnboardingSpot',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',
    'Shared/Framework/Mithril/Scripts/Helpers/Carousel',
    'Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
    'Shared/Framework/Mithril/Scripts/Helpers/Params',
    'DanskeSpil/Domain/Onboarding/Scripts/Helpers/Utils',
    'Common/Framework/EventHandling/Scripts/AppEvent'
  ],

  // WARNING THIS FILE IS GLOBAL. Erros will shut down the whole site. (bundle - core-footer.js)
  // https://viden.danskespil.dk/display/WU/Danskespil+.+Domain+.+Onboarding

  function (Component, Render, Storage, Carousel, Dictionary, Params, Utils, AppEvent) {

    // Variables:
    var baseURL = DS.Config.CONTEXTPREFIX + '/scapi/danskespil/onboarding/get';
    var dictionary = new Dictionary('/Onboarding');
    var render = Render();

    // Component:
    Component('onboarding-spot', [dictionary], function (m, route) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.$content = m.prop();
          this.$next = m.prop();
          this.$previous = m.prop();
          this.$shadow = m.prop();
          this.$wrapper = m.prop();
          this.dontShowAgain = m.prop(false);
          this.d = dictionary.get;
          this.dismissed = m.prop('');
          this.onboardingData = m.prop(null);
          this.storageName = m.prop('');
          this.showOnlyWhenParam = m.prop(Params.extract('show-onboarding-only-when'));

          this.dontShow = () => {
            if (this.onboardingData().confirmPermanentHide && this.dontShowAgain()) {
              Storage.set(this.storageName(), true);
            }

            AppEvent.fire('onboarding-close', { dontShowAgain: !this.onboardingData().confirmPermanentHide || this.dontShowAgain() });
          };

          this.clickHandler = (url) => {
            this.dontShow();
            window.location.href = url;
          };


          // Functions:
          this.closeWindow = () => {
            this.$content().classList.remove('fade-in'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            this.$shadow().classList.remove('fade-in'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            this.dontShow();
          };

          this.getPrimaryColor = function () {
            var data = this.onboardingData();

            if (data && data.primaryColor) {
              return data.primaryColor.Color;
            }
          }.bind(this);

          this.getSecondaryColor = function () {
            var data = this.onboardingData();

            if (data.secundaryColor) {
              return data.secundaryColor.Color;
            }
          }.bind(this);

          this.showOnlyIfParameterSet = function (isSet) {
            if (!isSet) return true;
            if (isSet && this.showOnlyWhenParam() === 'true') return true;
            return false;
          }.bind(this);

          this.initCarousel = function () {
            var $content = this.$content(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            var $next = this.$next(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            var $previous = this.$previous(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            var $shadow = this.$shadow(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            var $wrapper = this.$wrapper(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            var data = this.onboardingData();

            new Carousel($content, {
              withDots: true,
              withArrows: false,
              noFingerslide: data.noFingerSlide,
              prevButton: data.nextPrevButtons ? $previous : null,
              nextButton: data.nextPrevButtons ? $next : null,
              lastNextButtonEvent: this.closeWindow,
              lastNextButtonText: this.d('Finish'),
              activeDotColor: this.getSecondaryColor()
            });


            if (!this.dismissed()) {
              var delay = data.delay;
              $wrapper.style.visibility = 'visible'; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

              setTimeout(function () {
                $content.classList.add('fade-in'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
                $shadow.classList.add('fade-in'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
              }, (delay < 1 ? 1 : delay) * 1000);
            }
          }.bind(this);

          this.kvnNotVisible = function () {
            const messengerContent = document.querySelector('.operations-messenger-content');
            return messengerContent ? !getComputedStyle(messengerContent).display === 'none' : true;
          };

          this.mouseover = function (event) {
            var $video = event.target.children[0];

            if ($video && $video.nodeName === 'IFRAME' && $video.src.indexOf('autoplay=1') === -1) {
              var $symbol = $video.src.indexOf('?') > -1 ? '&' : '?';

              $video.src += $symbol + 'autoplay=1';
            }
          };

          // Context:
          if (location.search.toLowerCase().indexOf('skiponboarding') === -1) {
            Utils.getOnboardData({ url: location.pathname, device: Utils.getDevice() }, baseURL).then(function (data) {
              if (data) {
                this.storageName(data.id);
                this.dismissed(Storage.get(this.storageName()));
                this.onboardingData(data);

                m.redraw();
              }
            }.bind(this));
          }

          render.ready.then(function (nodes) {
            var $content = nodes['content'];
            var $next = nodes['next'];

            var $previous = nodes['previous'];
            var $shadow = nodes['shadow'];
            var $wrapper = nodes['wrapper'];

            if ($content && $content.$element) {
              this.$content($content.$element);
            }

            if ($next && $next.$element) {
              this.$next($next.$element);
            }

            if ($previous && $previous.$element) {
              this.$previous($previous.$element);
            }

            if ($shadow && $shadow.$element) {
              this.$shadow($shadow.$element);
            }

            if ($wrapper && $wrapper.$element) {
              this.$wrapper($wrapper.$element);
            }

            setTimeout(() => {
              this.initCarousel();
            }, 1000);
            // Detect onboarding IDs in the DOM and attach listeners:
            $content = this.$content(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            $shadow = this.$shadow(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

            if ($content && $shadow) {
              document.querySelector('a[href$="#onboarding"]')?.addEventListener('click', function () {
                $content.classList.add('fade-in');
                $shadow.classList.add('fade-in');
              });
            }
          }.bind(this));

        },
        view: function (controller) {

          // Variables:
          var data = controller.onboardingData();
          var primaryColor = controller.getPrimaryColor();

          // View:
          if (data && controller.showOnlyIfParameterSet(data.showOnlyIfParameterSet) && primaryColor && controller.kvnNotVisible()) {
            return m('div', { class: 'onboardingspot-overlay__wrapper', config: render.depend('wrapper'), style: 'visibility:hidden' }, [
              m('div', { class: 'onboardingspot-overlay__shadow', config: render.depend('shadow'), onclick: controller.closeWindow }),
              m('div', { class: 'onboardingspot-overlay__content', config: render.depend('content') }, [
                m('div', { class: 'onboardingspot-overlay__outer-wrapper' }, [
                  m('div', { class: 'onboardingspot-overlay__inner-wrapper' }, [
                    data.elements.map(function (element) {
                      return m('div', { class: 'onboardingspot-overlay__list-element', onmouseover: controller.mouseover }, [
                        element.image ? m('img', { src: element.image }) : element.video ? m('iframe', { class: 'onboardingspot-overlay__video', width: '100%', allow: 'autoplay', muted: 'muted', src: element.video, frameborder: 0 }) : null,
                        m('div', m.trust(element.text)),
                        element.link ? m('a', { onclick: () => { controller.clickHandler(element.link.Url); }, class: 'onboardingspot-overlay__list-element--link' }, element.link.Text) : null,
                        m('br'),
                        element.ctaLink ? m('a', { onclick: () => { controller.clickHandler(element.ctaLink.Url); }, class: 'onboardingspot-overlay__cta', style: 'background-color: ' + primaryColor }, element.ctaLink.Text) : null,
                      ]);
                    })
                  ])
                ]),
                data.nextPrevButtons ? m('div', { class: 'onboardingspot-overlay__button-container' }, [
                  m('svg', { class: 'onboardingspot-overlay__prev-cta', config: render.depend('previous'), style: 'visibility: hidden; fill: ' + primaryColor }, [
                    m('use', { href: '/Components/DanskeSpil/Domain/Onboarding/Graphics/SpriteSheets/icons.svg#Arrow' })
                  ]),
                  m('a', { href: '#', class: 'onboardingspot-overlay__next-cta', config: render.depend('next'), style: 'background-color: ' + primaryColor }, controller.d('Next')),
                  m('div', { class: 'onboardingspot-overlay__flex-ghostblock' })
                ]) : null,
                controller.onboardingData().confirmPermanentHide ?
                  m('label', { class: 'onboardingspot-overlay__dontshow', style: 'color: ' + primaryColor }, controller.d('DontShowAgain'), [
                    m('input[type=checkbox]', { onclick: m.withAttr('checked', controller.dontShowAgain), checked: controller.dontShowAgain() }),
                    m('span', { style: (controller.dontShowAgain()) ? 'background-color: ' + primaryColor : '' })
                  ])
                  : '',
                m('img.onboardingspot-overlay__closebutton', { onclick: controller.closeWindow, src: '/Components/DanskeSpil/Domain/Onboarding/Graphics/SpriteSheets/icons.svg#close_use' })
              ])
            ]);
          }
        }

      };

      // Routes:
      route('/', root);

    });

  });
