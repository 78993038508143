defineDs('Shared/Framework/Mithril/Scripts/Helpers/Render',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Functions:
    var render = function () {

      // Variables:
      var count = 0;
      var deferred = m.deferred();
      var nodes = {};
      var ready = deferred.promise;

      // Functions:
      var depend = function (name, callback) {
        count++;

        var internals = { count: count, name: name || Math.ceil((Math.random() * 10000000000)) };

        return function ($element, rendered, context) {
          var node = { $element: $element, rendered: rendered, context: context };

          if (typeof callback !== 'undefined') {
            callback($element, rendered, context);
          }

          if (nodes[internals.name]) {
            var collection = !nodes[internals.name].length ? [nodes[internals.name]] : nodes[internals.name];

            collection.push(node);

            nodes[internals.name] = collection;
          } else {
            nodes[internals.name] = node;
          }

          if (internals.count === 1) {
            deferred.resolve(nodes);
          }
        };
      };

      // Return:
      return {
        depend: depend,
        ready: ready
      };

    };

    // Public functions:
    return render;

  });
