defineDs('Shared/Framework/Mithril/Scripts/Helpers/Dictionary',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'Common/Framework/ErrorHandling/Scripts/ErrorLogging'
  ],
  function (m, ApiRequest, ErrorLogging) {

    // Variables:
    var Dictionary = function (path) {
      var dictionary = m.prop({});
      var loaded = m.prop(false);
      var ready = m.deferred();
      var loggedMissingKeys = [];

      var sentryLogMissing = function (key) {
        if (loggedMissingKeys.indexOf(key) === -1 && DS.Config.SENTRY_LOG_MISSING_DICTIONARY_KEYS) {
          loggedMissingKeys.push(key);
          var fullPath = path + '/' + key;

          if (Math.floor(Math.random() * 50) === 0) {
            try {
              ErrorLogging.capture('Missing dictionary: ' + fullPath, {
                extra: {
                  fullPath: fullPath,
                  key: key,
                  path: path
                },
                level: 'warning',
                tags: { custom: 'Dictionary' }
              });
            } catch (e) { }
          }
        }
      };

      var get = function (key, replacements) {
        if (this && this.prefix) {
          key = this.prefix + key;
        }

        replacements = replacements || {};

        if (loaded()) {
          var base = dictionary();
          var segments = key.split('/');

          for (var i = 0; i < segments.length; i++) {
            var segment = segments[i];

            if (typeof base[segment] === 'undefined') {
              sentryLogMissing(key);
              console.warn('Dictionary key not found: ' + key);

              break;
            }

            base = base[segment];
          }

          if (base instanceof Array) {
            base = base[Math.floor(Math.random() * base.length)];
          }

          // Replacements
          if (typeof base === 'string') {
            var keys = Object.keys(replacements);

            for (i = 0; i < keys.length; i++) {
              var k = keys[i];
              var val = replacements[k];

              base = base.replace(new RegExp('{' + k + '}', 'gi'), val);
            }
          }

          return base != null ? base : '!';
        }

        return '?';
      };

      // Initialize:

      var url = DS.Config.CONTEXTPREFIX + '/scapi/common/dictionary/dictionary?path=' + path + '&variants=1';

      // ApiRequest will take care of localstorage caching and avoiding multiple request to same ressource
      ApiRequest({
        method: 'GET',
        url: url,
        localCacheTTL: DS.Config.DICTIONARY_TTL || 60, // Seconds to cache in localStorage
        noCache: false // Dont add ?noCache queryparam, as it would break 304 Not Modified headers
      }).then(function (response) {

        var sanitizedDictionary = response[Object.keys(response)[0]];
        dictionary(sanitizedDictionary);

        loaded(true);

        ready.resolve();
      });

      // Public functions:
      return {
        dictionary: dictionary,
        get: get,
        ready: ready.promise
      };

    };

    return Dictionary;

  });
