defineDs('Shared/Framework/Mithril/Scripts/Helpers/Carousel',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/Hammer'
  ],
  function (Hammer) {
    // Init:
    return function ($element, settings) {
      // Variables:
      var isDesktop = document.body.classList.contains('is-desktop-detected');
      var isMobile = document.body.classList.contains('is-mobile-detected');
      var isTablet = document.body.classList.contains('is-tablet-detected');
      var $carousel = $element.children[0];

      var $carouselOuterWrapper = $carousel.children[0];
      var carouselOuterWidth = $carousel.getBoundingClientRect().width; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      var carouselItemsWrapperWidth = $carouselOuterWrapper.getBoundingClientRect().width; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

      // item styles
      var carouselItemStyles = window.getComputedStyle ? getComputedStyle($carouselOuterWrapper.children[0], null) : $carouselOuterWrapper.children[0].currentStyle;
      var carouselItemMarginRight = parseFloat(carouselItemStyles.marginRight, 10) || 0;
      var carouselItemWidth = parseFloat(carouselItemStyles.width, 10);

      var numberOfItemsPerSlide = Math.round((carouselOuterWidth + carouselItemMarginRight) / (carouselItemWidth + carouselItemMarginRight));
      var slideDistanceOnSlide = (carouselItemWidth + carouselItemMarginRight) * numberOfItemsPerSlide;

      var carouselPosition = 0;
      var positionPerSlide = 0;

      var nextButtonOriginalText = '';

      var numbersOfSlides = Math.ceil(carouselItemsWrapperWidth / slideDistanceOnSlide);

      if ((settings.notDesktop && isDesktop) || (settings.notMobile && isMobile) || (settings.notTablet && isTablet) || (numbersOfSlides <= 1)) {
        return;
      }

      // Functions:
      var currentSlideNumber = 1;

      var slide = function (xPosition) {
        if (!settings.wrapAround) {
          $carouselOuterWrapper.style.transform = 'translate(' + xPosition + 'px)';
        }
      };

      var snapSlide = function (xPosition) {
        if (Math.abs(carouselPosition) <= carouselItemMarginRight) {
          carouselPosition = 0;
        }

        slide(xPosition);

        currentSlideNumber = Math.round(Math.abs(xPosition / slideDistanceOnSlide) + 1);

        if (currentSlideNumber > numbersOfSlides) {
          currentSlideNumber = numbersOfSlides;
        }

        if (settings.withDots) {
          var $dotsWrapper = $element.querySelector('.dots-wrapper'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

          if (settings.activeDotColor) {
            $dotsWrapper.querySelector('.active').style.background = ''; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            $dotsWrapper.querySelectorAll('.dot')[currentSlideNumber - 1].style.background = settings.activeDotColor; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          }
          $dotsWrapper.querySelector('.active').classList.remove('active'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          $dotsWrapper.querySelectorAll('.dot')[currentSlideNumber - 1].classList.add('active'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        }

        if (settings.wrapAround) {
          $carouselOuterWrapper.setAttribute('data-active-slide', currentSlideNumber); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        }

        if (settings.withArrows) {
          var $arrowLeft = $element.querySelector('.carousel-arrow.left'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          var $arrowRight = $element.querySelector('.carousel-arrow.right'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          if (currentSlideNumber > 1 || settings.wrapAround) {
            $arrowLeft.classList.add('active');
          } else {
            $arrowLeft.classList.remove('active');
          }
          if (currentSlideNumber < numbersOfSlides || settings.wrapAround) {
            $arrowRight.classList.add('active');
          } else {
            $arrowRight.classList.remove('active');
          }
        }

        if (settings.prevButton && isDesktop) {
          currentSlideNumber === 1 ? settings.prevButton.style.visibility = 'hidden' : settings.prevButton.style.visibility = 'visible';
        }

        if (settings.nextButton && settings.lastNextButtonText) {
          if (currentSlideNumber === numbersOfSlides) {
            if (nextButtonOriginalText === '') {
              nextButtonOriginalText = settings.nextButton.textContent;
            }
            settings.nextButton.textContent = settings.lastNextButtonText;
          } else if (nextButtonOriginalText !== '') {
            settings.nextButton.textContent = nextButtonOriginalText;
          }
        }
      };

      var goToSlide = function (slide) {
        slide = Number(slide);
        currentSlideNumber = slide;
        carouselPosition = ((slide * slideDistanceOnSlide) * -1);
        snapSlide(carouselPosition);
      };

      if (settings.prevButton) {
        settings.prevButton.addEventListener('click', (ev) => {
          ev.preventDefault();
          goToSlide(currentSlideNumber - 2);
        });
      }

      if (settings.nextButton) {
        settings.nextButton.addEventListener('click', (ev) => {
          if (currentSlideNumber < numbersOfSlides) {
            ev.preventDefault();
            goToSlide(currentSlideNumber);
          } else {
            if (settings.lastNextButtonEvent) {
              settings.lastNextButtonEvent();
            }
          }
        });
      }

      if (!settings.noFingerslide || isMobile) {

        var hammer = new Hammer($element);
        hammer.get('pan').set({ threshold: 0 });

        hammer.on('panstart', function () {
          positionPerSlide = 0;
          $element.classList.add('slide-on');
        });

        hammer.on('panmove', function (event) {
          var deltaX = event.deltaX;

          positionPerSlide = deltaX;
          slide(deltaX + carouselPosition);
        });

        hammer.on('panup panend pancancel', function () {
          $element.classList.remove('slide-on');

          if (Math.abs(positionPerSlide) > (carouselOuterWidth / 10)) {
            if (positionPerSlide > 0) {
              if (carouselPosition < 0) {
                carouselPosition += slideDistanceOnSlide;
              }
            } else {
              if ((carouselPosition - slideDistanceOnSlide) > ((carouselItemsWrapperWidth - carouselItemMarginRight) * -1)) {
                carouselPosition -= slideDistanceOnSlide;
              }
            }
          }

          snapSlide(carouselPosition);
        });
      }

      if (settings.wrapAround) {
        // We cannot set position:absolute on cards before carousel.js has finished it's logic,
        // so we add this class instead, and set position:absolute on that one.
        $carouselOuterWrapper.classList.add('hero-livecasino-games__inner-wrapper--active');
      }

      // generate dots.
      if (settings.withDots) {
        $element.classList.add('with-dots');

        var dotsWrapperDOM = document.createElement('div');
        dotsWrapperDOM.classList.add('dots-wrapper');

        for (var i = 0; i < numbersOfSlides; i++) {
          var dotDOM = document.createElement('div');
          dotDOM.setAttribute('data-carousel-dot', i);
          dotDOM.classList.add('dot');

          if (i === 0) {
            dotDOM.classList.add('active');
            if (settings.activeDotColor) {
              dotDOM.style.background = settings.activeDotColor;
            }
          }

          dotDOM.addEventListener('click', function (ev) {
            goToSlide(ev.currentTarget.getAttribute('data-carousel-dot'));
          });

          dotsWrapperDOM.appendChild(dotDOM);
        }

        $carousel.appendChild(dotsWrapperDOM); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      }

      // generate arrows.
      if (settings.withArrows) {
        var slideTo = 0;
        var iconDOM = '<svg class="arrow-icon"><use href="/Components/DanskeSpil/Domain/PlayTogether/Graphics/SpriteSheets/PlayTogetherIcons.svg#arrow"></use></svg>';
        // prev arrow
        var arrowLeftDOM = document.createElement('div');
        arrowLeftDOM.classList.add('carousel-arrow');
        arrowLeftDOM.classList.add('left');

        if (settings.wrapAround) {
          arrowLeftDOM.classList.add('active');
        }

        arrowLeftDOM.addEventListener('click', function () {
          slideTo = (currentSlideNumber - 2) >= 0 ? currentSlideNumber - 2 : settings.wrapAround ? numbersOfSlides - 1 : 0;
          goToSlide(slideTo);
        });

        arrowLeftDOM.innerHTML = iconDOM.trim();
        $element.appendChild(arrowLeftDOM); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

        // next arrow
        var arrowRightDOM = document.createElement('div');
        arrowRightDOM.classList.add('carousel-arrow');
        arrowRightDOM.classList.add('right');

        if (currentSlideNumber < numbersOfSlides || settings.wrapAround) {
          arrowRightDOM.classList.add('active');
        }

        arrowRightDOM.addEventListener('click', function () {
          slideTo = ((currentSlideNumber + 1) <= numbersOfSlides ? currentSlideNumber + 1 : settings.wrapAround ? 1 : numbersOfSlides) - 1;
          goToSlide(slideTo);
        });

        arrowRightDOM.innerHTML = iconDOM.trim();
        $element.appendChild(arrowRightDOM); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      }
    };
  });
