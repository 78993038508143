defineDs('Shared/Framework/Ensighten/Scripts/BlockedCookiesHandler', [], function () {
  const getEmptyIframes = () => document.querySelectorAll('iframe[src="//javascript:;"]');

  const blockedCookiesHandler = document.querySelector('.blocked-cookies-handler');
  const hasAtLeastOneEmptyIframe = () => getEmptyIframes().length > 0;

  const getCookieState = () => {
    let cookieStates = window.Bootstrapper?.gateway.getCookieTypes() || [];
    cookieStates = cookieStates.map((cookieType) => window.Bootstrapper.gateway.getCookie(cookieType));
    if (cookieStates.includes('0')) return 'not-all-accepted';
    if (cookieStates.includes(undefined)) return 'unknown';
    if (cookieStates.every((a) => a === '1')) return 'all-accepted';
  };

  const setContentOfEmptyIframes = () => {
    getEmptyIframes().forEach((iframe) => {
      iframe.src = '/blockedcookieshandler';
    });
  };

  window.Bootstrapper?.bindDOMLoaded?.(() => {
    if (!hasAtLeastOneEmptyIframe()) {
      return;
    }

    const cookieState = getCookieState();

    if (!cookieState || cookieState === 'all-accepted') {
      return;
    }

    if (cookieState === 'not-all-accepted') {
      setContentOfEmptyIframes();
    }

    if (cookieState === 'unknown') {
      const interval = setInterval(() => {
        const cookieState = getCookieState();

        if (cookieState === 'all-accepted' || cookieState === 'not-all-accepted') {
          clearInterval(interval);

          if (cookieState === 'not-all-accepted') {
            setContentOfEmptyIframes();
            return;
          }

          // Cookies have been accepted. Reload the page to get the content of the iframe.
          location.reload();
        }
      }, 500);
    }
  });

  if (blockedCookiesHandler) {
    const cta = blockedCookiesHandler.querySelector('.blocked-cookies-handler__cta');

    cta.addEventListener('click', () => {
      const cookieModalButtons = parent.document.querySelectorAll('.ensButtons button');

      cookieModalButtons.forEach((cookieModalButton) => {
        cookieModalButton.addEventListener('click', () => {
          setTimeout(() => {
            if (getCookieState() === 'all-accepted') {
              // Cookies have been accepted. Reload the page to get the content of the iframe.
              parent.location.reload();
            }
          }, 200);
        });
      });

      parent.Bootstrapper.gateway.openModal();
    });

  }

});
