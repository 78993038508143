defineDs('Common/Framework/BoostAi/Scripts/BoostAi', [
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
], function (LoginCache, Storage, ApiRequest) {
  var button = document.getElementsByClassName('js-boostAi')[0];
  var enabled = (button ? button.dataset.enabled === 'True' : false);
  var inactivity = button ? button.dataset.inactivity === 'True' : false;
  var inactivityTimer = button ? parseInt(button.dataset.inactivitytimer, 10) : 0;
  var baseUrl = button ? button.dataset.baseurl : '';
  var hasShown = false;
  var idleTime = 0;
  var chatPanel = null;

  var init = function () {
    if (!inactivity || inactivityTimer <= 0 || !enabled) {
      return;
    }

    button.classList.add('aiboost-button');

    // Increment the idle time counter every second.
    setInterval(timerIncrement, 1000); // 1 second

    // Zero the idle timer on mouse movement.
    ['keydown', 'click'].forEach(function (type) {
      window.addEventListener(type, function () {
        idleTime = 0;
      });
    });
  };

  var timerIncrement = function () {
    if (hasShown) {
      return;
    }

    idleTime = idleTime + 1;
    if (idleTime === inactivityTimer) { // seconds
      button.classList.add('aiboost-button--visible');
    }
  };

  var loadJs = function (url, cb, inactivity) {
    if (!url || !enabled) {
      return;
    }

    if (document.getElementById('boostAiScript')) {
      cb && cb(inactivity);
      return;
    }

    var script = document.createElement('script');
    script.setAttribute('src', url);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'boostAiScript');

    var loaded = false;
    var loadFunction = function () {
      if (loaded) return;
      loaded = true;
      cb && cb(inactivity);
    };
    script.onload = loadFunction;
    script.onreadystatechange = loadFunction;
    document.head.appendChild(script);
  };

  var openChat = function () {
    var ttl = 1 * 24 * 60 * 60;

    chatPanel.addEventListener('conversationIdChanged', function (event) {
      var conversationId = event.detail.conversationId;
      var userObj = LoginCache.getUserObj();

      Storage.set('boostaiconversationid', conversationId, ttl, { skipContext: true }); // TTL 1 day

      if (userObj && userObj.customerInfo) {
        ApiRequest({
          method: 'POST',
          data: {
            command: 'POST',
            type: 'trigger_action',
            id: '4487',
            conversation_id: conversationId, // eslint-disable-line camelcase -- API parameter
            custom_payload: { // eslint-disable-line camelcase -- API parameter
              full_name: userObj.customerInfo.firstName + ' ' + userObj.customerInfo.lastName, // eslint-disable-line camelcase -- API parameter
              customerid: userObj.customerInfo.customerId,
              email: userObj.customerInfo.email
            }
          },
          url: baseUrl + '/api/chat/v2',
        });
      }
    });

    chatPanel.addEventListener('chatPanelClosed', function () {
      Storage.set('boostaiconversationid', null, ttl, { skipContext: true });
      chatPanel = null;
    });

    chatPanel.show();

    hasShown = true;

    if (window.dataLayer) {
      var action = 'aktiver' + (inactivity ? '_inactivity' : '');

      window.dataLayer.push({
        event: 'gaEvent',
        eventSyntax: {
          action: action,
          category: 'chatbot',
          label: window.dataLayer[0]?.page?.breadcrumb
        }
      });
    }
  };

  var openWindow = function () {
    var actionFilter = '';
    var boostAi = document.querySelector('.js-boostAi');

    if (boostAi) {
      actionFilter = boostAi.dataset.actionfilter;
    }

    if (chatPanel === null) {
      chatPanel = window.boostChatPanel({
        apiUrlBase: baseUrl + '/api',
        skill: '5731v000000Xnui',
        conversationId: Storage.get('boostaiconversationid', { skipContext: true }),
        filterValues: [actionFilter]
      });
    } else {
      chatPanel.setFilterValues([actionFilter]);
    }

    setTimeout(function () { button.classList.remove('loading'); }, 400);
    openChat(chatPanel);
  };

  if (document.readyState === 'loading') {  // Loading hasn't finished yet
    document.addEventListener('DOMContentLoaded', init);
  } else { // `DOMContentLoaded` has already fired
    init();
  }

  // Click event listener
  if (button) {
    button.addEventListener('click', function () {
      button.classList.add('loading');
      loadJs(baseUrl + '/chatPanel/chatPanel.js', openWindow);
    });
  }
});
