defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Components/ResponsibleGaming', [
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils',
], function (LoginCache, TimeUtils) {

  const topElement = document.querySelector('.js-responsible-gaming-top');
  const menuElement = document.querySelector('.js-responsible-gaming-menu');

  if (!(topElement && menuElement)) {
    return;
  }

  const { isLoggedIn } = LoginCache;
  const {
    timeDifferenceInSeconds,
    getCompoundTimeObject,
    getCurrentDateTime,
    getServerDateTime,
  } = TimeUtils;

  const isContextNeutral = topElement.dataset.isContextNeutral === 'true';
  const clockElement = topElement.querySelector('.js-responsible-gaming-top-clock');
  const openMenuToggle = topElement.querySelector('.js-responsible-gaming-top-toggle');
  const closeMenuToggle = menuElement.querySelector('.js-responsible-gaming-menu-toggle');

  const openMenu = () => {
    document.dispatchEvent(new CustomEvent('overlay:navigation:show'));
    menuElement.classList.add('open');
  };

  const closeMenu = () => {
    document.dispatchEvent(new CustomEvent('overlay:navigation:hide'));
    menuElement.classList.remove('open');
  };

  const padNumber = (number) => number.toString().padStart(2, '0');

  const updateClock = () => {
    if (!isContextNeutral && isLoggedIn()) {
      const loginDate = new Date(LoginCache.getUserObj().customerInfo.loginAt);
      const dateDiff = timeDifferenceInSeconds(loginDate, getServerDateTime());
      const timeObject = getCompoundTimeObject(dateDiff * 1000);
      const hours = (timeObject.days * 24) + timeObject.hours;
      const minutes = timeObject.mins;
      const seconds = timeObject.secs;
      clockElement.textContent = `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
    } else {
      const now = getCurrentDateTime();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      clockElement.textContent = `${padNumber(hours)}:${padNumber(minutes)}`;
    }
  };

  if (clockElement) {
    updateClock();
    setInterval(updateClock, 1000);
  }

  const initialize = () => {
    openMenuToggle?.addEventListener('click', openMenu);
    closeMenuToggle?.addEventListener('click', closeMenu);

    document.addEventListener('responsible-gaming:close', () => {
      closeMenu();
    });
  };

  initialize();

});
