defineDs('Common/Framework/EventHandling/Scripts/AppEvent', function () {

  /**
   * This is a bridge between webview (our page) and Native App (currently Isobar Lotto app), that embeds the webview,
   * for event communication such as tracking etc
   */

  var fire = function (name, data) {
    console.debug('AppEvent.fire', name, data);

    // ReactNativeVewView only accceseble from the App, so wrap with try/catch
    try {
      var jsonString = JSON.stringify({ name: name, data: data });
      window.ReactNativeWebView.postMessage(jsonString);
    } catch (ignoreError) { }
  };


  // Not implemented yet:
  // var subscribe = function (name, callback) {
  // };

  return {
    fire: fire
  };

});
