defineDs('DanskeSpil/Domain/Header/Scripts/HeaderNavigationMobile', [
  'Common/Framework/EventHandling/Scripts/OutsideClick',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (OutsideClick, Utils) {
  var $html = document.documentElement;
  var $nav = document.querySelector('.top-navigation');
  var $menuToggleButtons = document.querySelectorAll('.menu-toggle-btn');
  var $mobileNavs = document.querySelectorAll('.header .mobile-nav-left, .header .mobile-nav-right');
  var isMobile = Utils.isMobile();
  var isTablet = Utils.isTabletDevice();
  var windowWidth = document.body.clientWidth;

  var hasMobileNavBar = function () {
    return window.matchMedia('(max-width: 949px)').matches;
  };

  var shouldHaveGamesMobileNav = function () {
    if (document.body.classList.contains('region-frontpage') || document.body.classList.contains('region-avalon-frontpage')) {
      return false;
    }
    return true;
  };

  var addGamesMobileNav = function () {
    // Grab the first item in the navigation (the link that contains 'Alle spil') and generate a link
    var gamesHTML =
      '<div class="corporate-nav__list-item js-item js-has-subnav games-mobile-nav" style="bottom: 0">' +
      document.querySelector('.corporate-nav__list .js-has-subnav:first-child')?.innerHTML +
      '</div>';

    // Add the link to the left nav
    document.querySelector('.mobile-nav-left')?.insertAdjacentHTML('beforeend', gamesHTML);
  };

  var addAccountMobileNav = function () {
    // copy dropdown menu to mobile right menu
    // this is also done for desktop to facilitate turning tablet
    var accountHTML = document.querySelector('.accountmenu .sub-navigation.mega-menu')?.innerHTML;
    var corporateLogo = document.querySelector('.corporate-nav__logo')?.innerHTML;

    document.querySelector('.mobile-nav-right')?.insertAdjacentHTML('beforeend', accountHTML);

    var buttonsBlocktHTML = document.querySelector('.accountmenu .sub-navigation.mega-menu .mega-menu__account-details')?.innerHTML;
    document.querySelector('.mobile-nav-right .mega-menu__account')?.insertAdjacentHTML('beforeend', buttonsBlocktHTML);

    document.querySelector('.mobile-nav-right .mobile-navigation-header__logo')?.insertAdjacentHTML('beforeend', corporateLogo);
  };

  var toggleMenu = function (side) {
    $nav.style.pointerEvents = 'all';
    var opposite = side === 'left' ? 'right' : 'left';
    var toggled = $nav.classList.contains('is-showing-' + side + '-menu');

    $nav.classList.remove('is-hidden');
    document.querySelector('[data-menu-toggle][href="#' + side + 'menu"]')?.setAttribute('aria-expanded', !toggled);
    $nav.classList.remove('is-showing-' + opposite + '-menu');
    $nav.classList.toggle('is-showing-' + side + '-menu');
    $html.classList.toggle('lock');
    document.body.classList.toggle('lock');
  };

  var closeMobileNavs = function () {
    document.querySelector('.mobile-user-info-inner-wrapper')?.classList.remove('overlay-active');

    document.querySelectorAll('.page-area').forEach(($element) => {
      $element.classList.remove('modal-wrapper', 'overlay-active');
    });

    $nav.classList.remove('is-showing-left-menu', 'is-showing-right-menu', 'is-hidden');

    $menuToggleButtons.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      $element.classList.remove('active');
      $element.setAttribute('aria-expanded', false); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    });

    $html.classList.remove('lock');
    document.body.classList.remove('lock');
  };

  var setupOutsideClickHandler = function () {
    OutsideClick($nav, function (ev) {
      if ($nav.classList.contains('is-showing-left-menu') || $nav.classList.contains('is-showing-right-menu')) {
        ev.preventDefault();
        ev.stopPropagation();
        closeMobileNavs();
      }
    });
  };

  var resetNavBars = function () {
    closeMobileNavs();

    // clear fix for ipad/mobile below
    $mobileNavs.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      $element.style.height = '';
    });
  };

  var onResize = function () {
    // check window width has actually changed and it's not just iOS triggering a resize event on scroll
    if (document.body.clientWidth !== windowWidth) {
      // update the window width for next time
      windowWidth = document.body.clientWidth;

      resetNavBars();
    }
  };

  var gamesMobileNavHandler = function () {
    var gamesButtonHeight = document.querySelector('.games-mobile-nav').offsetHeight;
    this.classList.toggle('active');

    if (this.classList.contains('active')) {
      // Default variables used throughout the script
      var gamesOpenPos = window.innerHeight - document.querySelector('.mobile-navigation-header').offsetHeight;

      document.querySelector('.games-mobile-nav .sub-navigation').style.height = (gamesOpenPos - gamesButtonHeight) + 'px';
    } else {
      document.querySelector('.games-mobile-nav .sub-navigation').style.height = 0;
      document.querySelector('.games-mobile-nav .sub-navigation').classList.remove('active');
    }
  };

  var menuToggleHandler = function (ev) {
    if (this.getAttribute('href')?.substr(0, 1) === '#') {
      ev.preventDefault();
      var side = this.href.indexOf('left') > -1 ? 'left' : 'right';

      document.querySelector('.mobile-user-info-inner-wrapper')?.classList.toggle('overlay-active');

      document.querySelectorAll('.page-area').forEach(($element) => {
        $element.classList.toggle('modal-wrapper', 'overlay-active');
      });

      toggleMenu(side);
      if (side === 'left') {
        this.classList.toggle('active');
      }
      document.querySelector('.mobile-nav-' + side + ' .menu-toggle-btn')?.classList.add('active');
      if (side === 'right') {
        document.querySelector('.menu-toggle-' + side + ' .menu-toggle-btn')?.classList.add('active');
        document.querySelector('.brand-nav')?.removeAttribute('style');
      }
    }
  };

  // the content of this function MIGHT be moved to menuToggleHandler (here be dragons)
  var menuButtonHandler = function () {
    if (hasMobileNavBar()) {
      // fix for ipad/mobile
      $mobileNavs.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        $element.style.height = window.innerHeight + 'px';
      });
    }
  };

  var bindEvents = function () {
    if (!window.sitecoreEnv && (isMobile || isTablet)) {
      $menuToggleButtons.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        $element.addEventListener('click', menuButtonHandler); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      });
    }

    document.body.addEventListener('click', (evt) => {
      var $matchedMenuToggle = evt.target.closest('.top-navigation [data-menu-toggle]');
      if ($matchedMenuToggle) {
        menuToggleHandler.call($matchedMenuToggle, evt);
      }

      var $matchedGamesMobileNav = evt.target.closest('.header .games-mobile-nav');
      if ($matchedGamesMobileNav) {
        gamesMobileNavHandler.call($matchedGamesMobileNav, evt);
      }
    });
  };

  // Init function, called at bottom
  var initialize = function () {
    if (!$nav) {
      return;
    }

    bindEvents();

    if (shouldHaveGamesMobileNav()) {
      addGamesMobileNav();
    }

    addAccountMobileNav();
    setupOutsideClickHandler();

    resetNavBars();

    window.addEventListener('resize', onResize);
  };

  initialize();

});
