defineDs('DanskeSpil/Project/Design/Js/Global/RetailerId', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (Utils) {

  // Init:
  var retailerId = Utils.getParameter('retailerId');
  var btag = Utils.getParameter('btag');
  var domain = 'danskespil.dk';

  if (retailerId != null) {
    // logDeprecated: Still in use per 13/8-2019
    Utils.cookie('retailerId', retailerId, { path: '/', domain: domain });
  }

  if (btag != null) {
    // logDeprecated: Still in use per 13/8-2019
    Utils.cookie('btag', btag, { path: '/', expires: 30, domain: domain });
  }

});
