defineDs('DanskeSpil/Domain/Header/Scripts/HeaderToggleOnScroll', [], function () {

  // Default variables used throughout the script
  const deviceSize = window.screen.width >= 1024 ? 'large' : 'small';
  let lastScrollPosition = 0;

  // DOM:
  const $htmlbody = document.querySelectorAll('html, body');
  const $nav = document.querySelector('.top-navigation');
  const hasStaticHeader = Boolean($nav?.classList.contains('static-header'));
  const hasStaticSubHeader = Boolean($nav?.classList.contains('static-subheader'));

  /**
   * Calculate the height of the visible nav elements, banners, notications
   *
   * Some parts of the header is only shown on desktop, so we need to calculate the actual height
   * of the mobile header
   */
  const calculateVisibleMobileNavHeight = function () {
    const $bannersAndNotifications = document.querySelectorAll('.generic-smartbanner, .android-smartbanner, .notifications');
    let height = 0;

    $bannersAndNotifications.forEach(($element) => { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      if ($element.style.display !== 'none') {
        height += $element.getBoundingClientRect().height;  // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      }
    });

    return Math.max(height, 1); // MIPE: We dont know why 1 (...)
  };

  const calcNavigationHeight = function () {
    return parseFloat(getComputedStyle($nav, null).height);
  };

  const sumScrollTop = function (nodeList) {
    let sum = 0;
    nodeList?.forEach(($element) => {
      sum += $element?.scrollTop || 0;
    });
    return sum;
  };


  const hideTopNav = function () {
    let height = deviceSize === 'large' ? $nav.getBoundingClientRect().height : calculateVisibleMobileNavHeight(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

    if (hasStaticSubHeader) {
      height = height - 80;
    }

    if (!hasStaticHeader) {
      $nav.style.transform = 'translate3d(0, -' + height + 'px, 0)';

      if (deviceSize === 'large') {
        // NOTE: Only time-consuming and reality-check? What about smartbanners?
        document.querySelectorAll('header.top-navigation .time-consuming-notification-wrapper, header.top-navigation .reality-check-notification').forEach(($element) => {
          $element.style.transform = 'translate3d(0, ' + height + 'px, 0)';
        });
      }
    } else if (deviceSize !== 'large') {
      // NOTE: Only generic-smartbanner, time-consuming and reality-check? What about android-smartbanner?
      document.querySelectorAll('header.top-navigation .generic-smartbanner, header.top-navigation .time-consuming-notification-wrapper, header.top-navigation .reality-check-notification').forEach(($element) => {
        $element.style.transform = 'translate3d(0, ' + height + 'px, 0)';
      });
    }

    document.querySelectorAll('.sub-navigation.simple-menu').forEach(($element) => {
      $element.style.bottom = '3.6rem';
    });

    document.querySelectorAll('.navigation-item').forEach(($element) => {
      $element.classList.remove('has-shown-subnav');
    });
  };


  const checkHashInitially = function () {
    const scrollPosition = sumScrollTop($htmlbody);
    if (location.hash && location.hash !== '' && location.hash.substr(1, 2) !== '' && lastScrollPosition != scrollPosition) {
      lastScrollPosition = scrollPosition;
      hideTopNav();
    }
  };

  const bindScrollEvent = function () {
    document.addEventListener('scroll', function () {
      const scrollTop = sumScrollTop($htmlbody);

      if (scrollTop > lastScrollPosition && scrollTop > calcNavigationHeight()) {
        // Scrolled down
        hideTopNav();
      } else {
        // Scrolled up
        $nav.style.pointerEvents = 'all';
        $nav.style.transform = 'translate3d(0px, 0px, 0)';

        document.querySelectorAll('.sub-navigation.simple-menu').forEach(($element) => {
          $element.style.bottom = 0;
        });

        document.querySelectorAll('header.top-navigation .generic-smartbanner, header.top-navigation .time-consuming-notification-wrapper, header.top-navigation .reality-check-notification').forEach(($element) => {
          $element.style.transform = 'translate3d(0, 0, 0)';
        });
      }

      lastScrollPosition = scrollTop;
    });
  };

  const initialize = function () {
    if (window.sitecoreEnv || $nav === null) {
      return;
    }

    bindScrollEvent();
    setTimeout(checkHashInitially, 1000);
  };

  initialize();

});
