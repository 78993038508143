defineDs('DanskeSpil/Domain/PermissionPopUp/Scripts/Helpers/NotificationsApi', [
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest'
], function (ApiRequest) {

  // NOTICE: This is a copy of DanskeSpil/Domain/PlayerAccountManagement/Notifications/Scripts/Data/NotificationsApi
  // with the difference, that this version is using ApiRequest instead Request module.
  // The reason for this is that ApiRequest has some PAM script dependencies, which we wont include
  // on all other pages (PermissionPopupSpot is a global functionality).

  var getSettings = function () {
    return ApiRequest({
      method: 'GET',
      url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/integration/eloqua/playernotificationsettings/get',
      background: true
    });
  };

  var updateSettings = function (settings) {
    return ApiRequest({
      method: 'POST',
      data: settings,
      url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/integration/eloqua/playernotificationsettings/update',
      background: true
    });
  };

  var acknowledgeRecentlyUpgraded = function () {
    return ApiRequest({
      method: 'POST',
      url: DS.Config.CONTEXTPREFIX + '/scapi/danskespil/playeraccountmanagement/AcknowledgeRecentlyUpgraded',
      background: true
    });
  };

  return {
    getSettings: getSettings,
    updateSettings: updateSettings,
    acknowledgeRecentlyUpgraded: acknowledgeRecentlyUpgraded
  };
});
