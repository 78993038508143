defineDs('DanskeSpil/Framework/Navigation/Scripts/ReturnUrlCheck', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (Utils) {
  var validateParams = [
    'returnUrl',
    'loginSuccessUrl',
    'signupSuccessUrl',
    'redirectUrl',
    'cancelUrl' // used on loginpage
  ];

  var getParameter = function (key, queryString) {
    // if queryString not defined, use current location URL
    if (typeof queryString === 'undefined') {
      queryString = location.search.substring(1);
    }

    var params = queryString.split('&');
    for (var i = 0; i < params.length; i++) {
      var args = params[i].split('=');
      if (args[0].toLowerCase() == key.toLowerCase()) {
        return args[1];
      }
    }
    return null;
  };

  var isValidReturnUrl = function (url) {
    if (!url) {
      return false;
    }
    url = decodeURIComponent(url);

    if (url.indexOf('app://dummy') === 0) {
      return true;
    }

    if (url.indexOf('app://flow-complete') === 0) {
      return true;
    }

    if (url[0] === '/') {
      // If first character is '/', URL is relative to the existing origin
      url = window.location.origin + url;
    }
    if (url.substr(-1) !== '/') {
      // Ensure we have tailing check, for full-filling the regexp below
      url += '/';
    }

    // TODO: Allow a host of whitelisted domains to be checked against
    return (/^https:\/\/([a-zA-Z0-9-]*\.)*(danskespil\.dk|spigo\.com)\//i.test(url));
  };

  var i;

  for (i = 0; validateParams[i]; i++) {
    var param = validateParams[i].toLowerCase();
    var redirectPage = getParameter(param);

    if (!redirectPage) {
      continue;
    }
    var validReturnUrl = isValidReturnUrl(redirectPage);

    if (!validReturnUrl) {
      var fallbackReturnUrl = Utils.cookie(DS.Config.CONTEXT + 'currentBrandURL') || window.location.origin;
      if (!isValidReturnUrl(fallbackReturnUrl)) {
        fallbackReturnUrl = 'https://danskespil.dk';
      }

      // URL has been tampered with. Reload page and sent user to fallback URL after flow
      window.location.href = window.location.origin + window.location.pathname + '?' + param + '=' + fallbackReturnUrl;
      break;
    }
  }
});
