defineDs('Shared/Framework/Mithril/Scripts/Helpers/MithrilRedrawEverySecond',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
  ],
  function (m) {

    // Use this helper, if you make crappy code, that needs Mithril redraw every second

    var timer = null;

    var start = function () {
      if (!timer) {
        timer = setInterval(function () {
          m.redraw();
        }, 1000);
      }
    };

    var isActive = function () {
      return timer != null;
    };

    return {
      start: start,
      isActive: isActive
    };

  });
