defineDs('DanskeSpil/Domain/Authentification/Scripts/KeepSessionAliveTimer', [], function () {

  var _intervalTime = 30 * 60 * 1000; // 30 minutes
  var _counter = 0;
  var _refreshFunc;
  var _logoutFunc;
  var _isLoggedInFunc;
  var intervalId;

  var pooler = function () {
    var maxIterations = 24 * 60 * 60 * 1000 / _intervalTime; // max pool iterations in 24 hours
    /* Increment counter */
    _counter++;

    /* If less than 24 hours since page load, move on */
    if (_counter <= maxIterations) {
      if (_isLoggedInFunc()) {
        /* Renew session */
        _refreshFunc();
      }

    } else {
      /* After 24 hours, just logout */
      _logoutFunc();
      clearInterval(intervalId);
    }
  };

  // inject function for refresh session, logout and
  // check cookies for login status. All the functions must be
  // defined.
  var init = function (refreshFunc, logoutFunc, isLoggedInFunc) {
    if (typeof refreshFunc === 'function'
        && typeof logoutFunc === 'function'
        && typeof isLoggedInFunc === 'function') {
      _refreshFunc = refreshFunc;
      _logoutFunc = logoutFunc;
      _isLoggedInFunc = isLoggedInFunc;
      intervalId = window.setInterval(pooler, _intervalTime);
    } else {
      console.error('Parameters are not valid!');
    }
  };

  var stopRefresh = function () {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  };

  // public functions
  var publc = {
    init: init,
    stopRefresh: stopRefresh
  };
  return publc;
});
