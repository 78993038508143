defineDs('DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth', [
  'Shared/Framework/Mithril/Scripts/Helpers/Storage',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
], function (Storage, Utils, LoginCache, ApiRequest) {

  var getUserObj = function (callback, forceUpdate) {
    var options = {};
    if (typeof forceUpdate != 'undefined' && forceUpdate) {
      options.forceUpdate = '1';
    }

    // addDelay / circuit breaker -  Why do we do this:
    // Usually, we have ApiRequest (plus normal browser-caching) to ensure short-time caching of userinfo (among more)
    // However, if the first request hasnt completed before next requests is triggered, it cannot use the cache
    // ApiRequest takes care of this, by holding a list of requests in progress, and reuses the first promise.

    // However, If multiple tabs/iframes triggers an event that will start mulitple requests to eg userinfo at same time
    // we cannot use that feature. As a workaround, we will delay the next request(s) a bit, so the first has a chance
    // to complete.
    //
    // On eg Bingo-clients, this is (before this "fix") causing double userinfo request (because of multiple DS frames),
    // which is adding up (we currently see more than 6000 req/min in IIS logs when big Bingo Campaign is in place)
    // This is properly also causing double/tripple requests in other scenarios, which we wanna try limiting.

    var inProgress = Storage.get('GetUserInfo-InProgress') === '1';
    Storage.set('GetUserInfo-InProgress', '1', 1);

    Utils.delay(inProgress ? 300 : 0).then(function () {
      ApiRequest({
        url: DS.Config.CONTEXTPREFIX + '/scapi/ds/userinformation/userinformation',
        localCacheTTL: forceUpdate ? 0 : 2, // Disable localCache if we need to forceupdate
        data: options
      }).then(function (response) {
        Storage.set('GetUserInfo-InProgress', null, 1);

        if (response === null) {
          console.log('User object is null');
          callback(null);
          return;
        }
        correctLastloginTime(response);
        LoginCache.setUserObj(response);
        callback(response);
      }).catch(function () {
        Storage.set('GetUserInfo-InProgress', null, 1);
        LoginCache.setUserObj(null);
        callback(null);
      });

    });
  };

  var getLoginStatus = function (callback) {
    callback = callback || function () { };

    ApiRequest({
      url: DS.Config.CONTEXTPREFIX + '/scapi/ds/authentification/loginstatus',
      method: 'GET',
      localCacheTTL: 2
    }).then(function (response) {
      var loggedIn = response && response.loggedIn && response.loggedIn === 'true';
      callback(loggedIn);
    }).catch(function () {
      callback(false);
    });

  };

  var authWithAssertionId = function (assertionId, callback) {
    const url = DS.Config.CONTEXTPREFIX + '/scapi/ds/authentification/login?pageUrl=' + encodeURIComponent(location.href);
    const bodyData = 'assertionid=' + assertionId;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: bodyData
    }).then(async function (response) {
      try {
        var data = await response.json();
        // data should be like {loggedIn: "true", status: "Active", customerInfo: {...}}
        if (data.customerInfo) {
          correctLastloginTime(data);
          LoginCache.setUserObj({ customerInfo: data.customerInfo });
        }
        callback(data);
      } catch (e) {
        callback(null);
      }
    }, async function () {
      callback(null);
    });
  };

  var logout = function () {
    ApiRequest({
      url: DS.Config.CONTEXTPREFIX + '/scapi/ds/authentification/logout?pageUrl=' + encodeURIComponent(location.href),
      method: 'POST',
      avoidSimultaneous: true,
      localCacheTTL: 2
    });
  };

  var correctLastloginTime = function (userObj) {
    if (userObj
      && userObj.customerInfo
      && userObj.customerInfo.prevLoginTime
      && !isNaN(Date.parse(userObj.customerInfo.prevLoginTime))
    ) {
      var loginTime = new Date(userObj.customerInfo.prevLoginTime);
      var formattedDate = loginTime.toLocaleDateString('da-DK').replace(/[/|-]/g, '.') + ' kl. ' + loginTime.toLocaleTimeString('da-DK').replace(/\./g, ':').slice(0, -3);
      userObj.customerInfo.prevLoginTime = formattedDate;
    }
  };

  // public functions
  var publc = {
    authWithAssertionId: authWithAssertionId,
    logout: logout,
    getLoginStatus: getLoginStatus,
    getUserObj: getUserObj
  };
  return publc;
});
