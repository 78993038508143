/* global dsApplicationConfig */
defineDs('DanskeSpil/Domain/CookieRegulation/Scripts/CookieInfoWidget', [
  'Shared/Framework/Mithril/Scripts/Helpers/Storage'
], function (Storage) {

  if (window.dsApplicationConfig && (dsApplicationConfig.GameVendorViewType === 'webview' || dsApplicationConfig.GameVendorViewType === 'iframe')) {
    return; // dont show cookie info in webview or iframe
  }

  if (Storage.get('COOKIEINFO_SEEN', { skipContext: true }) == '1') {
    return;
  }

  var $cookieInfo = document.querySelector('.cookie-info');
  if (!$cookieInfo) {
    return;
  }

  document.body.classList.add('cookie-info-active');

  var $cta = $cookieInfo.querySelector('.js-seen'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
  if ($cta) {
    $cta.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      Storage.set('COOKIEINFO_SEEN', '1', 365 * 24 * 60 * 60, { skipContext: true });
      document.body.classList.remove('cookie-info-active');
    });
  }

});
