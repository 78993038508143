defineDs('Common/Framework/ResourceHandling/Scripts/SvgQuirks', function () {

  //   /* Quirk in IE11 where it doesn't want to href to an external svg.
  //   * We fix it by simply including them into the page and changing the href to an in document href.
  //   * This still caches properly due to XMLHttpRequest. */
  var existingSvgs = {};
  var ongoingRequests = {};

  var body = document.querySelector('body');

  var container = document.createElement('div');
  container.setAttribute('style', 'height: 0; width: 0; overflow: hidden; pointer-events: none; opacity: 0; z-index: -9999; top: -9999px; left: -9999px; position: absolute;');
  container.classList.add('svg-auto-include-container');

  var overrideStyle = document.createElement('style');
  overrideStyle.innerHTML = 'use { display: inline; }';

  var refNode = body.childNodes[0];
  body.insertBefore(overrideStyle, refNode);
  body.insertBefore(container, overrideStyle);

  var useReplacements = function (node) {
    var useTags = node.getElementsByTagName('use');
    Array.prototype.forEach.call(useTags, function (useTag) {
      var xlink = useTag.getAttribute('xlink:href') || useTag.getAttribute('href') || '';
      var hrefType = useTag.getAttribute('xlink:href') ? 'xlink:href' : 'href';
      if (xlink && xlink !== '' && xlink.substr(0, 1) !== '#' && xlink.indexOf('.svg') > -1) {
        var xlinkUrl = xlink.split('#')[0];
        var svgExists = existingSvgs[xlinkUrl];
        var requestIsOngoing = ongoingRequests[xlinkUrl];
        var xlinkParts = xlink.split('.svg')[0].split('/');
        var fileName = xlinkParts[xlinkParts.length - 1];
        var xlinkId = fileName;
        if (xlink.split('#').length > 1) {
          xlinkId = '#' + xlink.split('#')[1];
        }
        if (!svgExists && !requestIsOngoing) {
          ongoingRequests[xlinkUrl] = true;
          requestSvgDocument(xlink, function (err, res) {
            if (!err) {
              if (!existingSvgs[xlinkUrl]) {
                existingSvgs[xlinkUrl] = true;
                var parser = new DOMParser();
                var doc = parser.parseFromString(res, 'image/svg+xml');
                var ele = document.adoptNode(doc.documentElement);
                ele.setAttribute('id', fileName);
                container.appendChild(ele);
              }
              useTag.setAttribute(hrefType, xlinkId);
            }
          });
        } else {
          useTag.setAttribute(hrefType, xlinkId);
        }
      }
    });
  };

  var requestSvgDocument = function (url, callback) {
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.setRequestHeader('Content-Type', 'image/svg+xml');
    request.onload = request.onerror = function () {
      var error = request.readyState === 4 && request.status === 200 ? null : request.statusText;
      ongoingRequests[url] = false;
      callback(error, request.responseText);
    };
    request.send(null);
  };

  var observeMutations = function (mutations) {
    mutations.forEach(function (mutation) {
      Array.prototype.filter.call(mutation.addedNodes, function (node) { return node.nodeType === 1; }).forEach(function (node) {
        useReplacements(node);
      });
    });
  };

  var observer = new MutationObserver(observeMutations);

  var config = { childList: true, subtree: true };

  observer.observe(document, config);

  useReplacements(body);

});
