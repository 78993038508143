defineDs('Common/Framework/DeviceDetection/Scripts/BrowserRequirements', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Common/Framework/ErrorHandling/Scripts/ErrorLogging'
], function (Utils, ErrorLogging) {

  if (Utils.cookie('browser-deprecated') === 'dismissed') {
    return;
  }

  var browserRequirementsDiv = document.querySelector('.js-browser-requirements');
  var closeBottonDiv = document.querySelector('.js-browser-requirements .js-browser-close-button');
  if (!browserRequirementsDiv || !closeBottonDiv) {
    return;
  }

  browserRequirementsDiv.style.display = 'block';

  closeBottonDiv.addEventListener('click', function (event) {
    event.preventDefault();

    // Hide the Browser banner
    browserRequirementsDiv.style.display = 'none';

    // Set Cookie - Show banner once per day
    Utils.cookie('browser-deprecated', 'dismissed', { expires: 1, path: '/' });

    // Track to Sentry
    ErrorLogging.capture('Deprecated browser warning', { level: 'warning' });
  });

});
