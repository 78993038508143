defineDs('DanskeSpil/Framework/TimeUtils/Scripts/Clock', [
  'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils'
], function (TimeUtils) {

  var clock = document.getElementsByClassName('js-timeutils-clock');

  var fixMinSec = function (i) {
    return (parseInt(i, 10) < 10) ? '0' + i : i;
  };

  var updateTime = function (clocks) {
    var now = TimeUtils.getCurrentDateTime();
    var timeString = fixMinSec(now.getHours()) + ':' + fixMinSec(now.getMinutes());

    if (clocks === undefined) {
      clocks = clock;
    }
    Object.keys(clocks).forEach(function (clockKey) {
      clocks[clockKey].innerHTML = timeString;
    });
  };

  var setupTimer = function () {
    setInterval(updateTime, 1000);
  };

  var init = function () {
    setupTimer();
    updateTime();
  };

  var updateClock = function (clocks) {
    updateTime(clocks);
  };

  return {
    init: init,
    updateClock: updateClock
  };
});

