defineDs('DanskeSpil/Domain/Authentification/Scripts/WaitForAuthSynced', [
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController'
], function (m, Event, LoginController) {

  // Variables:
  var ready = m.deferred();

  // Initialize - Ready when page load auth sync is finished
  if (LoginController.isSynced()) {
    ready.resolve();
  } else {
    Event.subscribe('pageload-auth-synced', function () {
      ready.resolve();
    });
  }


  return {
    ready: ready.promise,
  };

});
