defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Components/Tooltip', [
  'DanskeSpil/Domain/Feature.Navigation/Scripts/Helpers/Debounce'
], function (Debounce) {

  const cssTooltip = [
    'relative',
    'underline',
    'decoration-dotted',
    'underline-offset-2',
    'cursor-pointer',
    'group',
    'after:!hidden',
  ];

  const cssPopOver = [
    'invisible',
    'absolute',
    'bottom-[2.2em]',
    'left-1/2',
    'translate-x-[-50%]',
    'translate-y-[5%]',
    'bg-[#ebebeb]',
    'p-8',
    'pb-10',
    'text-[1.2rem]/[1.5]',
    'text-[#222]',
    'w-[max-content]',
    'max-w-[35ch]',
    'rounded-[0.5rem]',
    'text-center',
    '[text-wrap:balance]',
    'transition-transform',
    'group-[.active]:visible',
    'group-[.active]:translate-y-0',
  ];

  const initialize = () => {
    const tooltips = document.querySelectorAll('[tooltip]');

    if (tooltips.length === 0) return;

    tooltips.forEach((tooltip) => {
      const text = tooltip.getAttribute('tooltip');
      if (!text) return;

      tooltip.classList.add(...cssTooltip);
      const popover = document.createElement('div');
      popover.innerText = text;
      popover.classList.add(...cssPopOver);
      tooltip.appendChild(popover);

      tooltip.addEventListener('click', () => {
        setTimeout(() => {
          Debounce(() => tooltip.classList.add('active'), 200, true);
        }, 100);
      });

      tooltip.addEventListener('mouseover', () => tooltip.classList.add('active'));
      tooltip.addEventListener('mouseout', () => tooltip.classList.remove('active'));
    });

    document.addEventListener('click', (ev) => {
      const activeTooltip = ev.target?.getAttribute('tooltip');

      if (!activeTooltip) {
        document.querySelectorAll('[tooltip].active')
          .forEach((tip) => tip.classList.remove('active'));
      }
    });

  };

  initialize();

});
