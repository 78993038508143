defineDs('DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils', function () {

  /*
   * Variables
   */

  // If pageloadLocalEpoch is not set already, set it now
  window.pageloadLocalEpoch = window.pageloadLocalEpoch || (new Date()).getTime();

  var secondsInAMinute = 60;
  var secondsInAnHour = secondsInAMinute * 60;
  var secondsInADay = secondsInAnHour * 24;

  /*
   * Methods
   */

  var getPageLoadIso8601 = function () {
    return document.body.getAttribute('data-time-now') || new Date().toISOString();
  };

  var iso8601DateTimeParse = function (dateTimeStr) {
    if (!dateTimeStr) return;

    var datePart = dateTimeStr.split('T')[0];
    var timePart = dateTimeStr.split('T')[1];
    // Strip millis and timezone
    if (timePart.indexOf('.') > -1) timePart = timePart.split('.')[0];

    // Return parsed values as map
    return {
      year: datePart.slice(0, 4),
      month: datePart.slice(5, 7),
      day: datePart.slice(8, 10),
      time: timePart,
      hours: timePart.split(':')[0],
      minutes: timePart.split(':')[1],
      seconds: timePart.split(':')[2] || 0
    };
  };


  /** ****************************************************************************
   * This function is deprecated as it returns a wrong date if you are sitting
   * in another timezone. Use getServerDateTime() instead.
   */
  var getCurrentDateTime = function () {
    /* Find the difference between server and client time */
    var pageloadServer = iso8601DateTimeParse(getPageLoadIso8601());
    if (!pageloadServer) return;

    var pageloadServerEpoch = new Date(pageloadServer.year, pageloadServer.month - 1, pageloadServer.day, pageloadServer.hours, pageloadServer.minutes, pageloadServer.seconds);

    // we cant meassure the HTTP server request time here, but it is likely always more than 100ms, so we add it to the difference
    var diff = pageloadServerEpoch - window.pageloadLocalEpoch + 100;

    /* Build new date object */
    var currentClientEpoch = (new Date()).getTime();
    return new Date(currentClientEpoch + diff);
  };

  /** ****************************************************************************
   * Server time is taken from body just after page load. Here after the local
   * time is added to compensate for the time passed since page load.
   */
  var getServerDateTime = function () {
    // Find the difference between server and client time
    var pageloadServer = getPageLoadIso8601();
    var pageloadServerEpoch = new Date(pageloadServer).getTime();
    // we cant meassure the HTTP server request time here, but it is likely always more than 100ms, so we add it to the difference
    var diff = pageloadServerEpoch - window.pageloadLocalEpoch + 100;
    // Build new date object
    var currentClientEpoch = (new Date()).getTime();
    return new Date(currentClientEpoch + diff);
  };

  /** ****************************************************************************
   * Take a date string without timezone like '2019-07-24T19:00:00' and add
   * timezone as on server time. If the timezone information is already on, this
   * will become double.
   */
  var timeAsServerTimeZone = function (timeStr) {
    var serverTime = getPageLoadIso8601();
    if (timeStr.indexOf('+') == -1 && serverTime && serverTime.indexOf('+') > -1) {
      var zone = serverTime.split('+')[1];
      timeStr = timeStr + '+' + zone;
    }
    return new Date(timeStr);
  };

  var timeDifferenceInSeconds = function (startDateTime, endDateTime) {
    startDateTime = new Date(startDateTime).getTime();
    endDateTime = new Date(endDateTime).getTime();
    var difInSeconds = (startDateTime - endDateTime) / 1000;
    return difInSeconds < 0 ? difInSeconds * -1 : difInSeconds;
  };

  /*
    convert how many seconds there are from hh:mm:ss to zero
    examples:
    23:58:00 = 86280
    00:05:00 = 300
    00:42    = 42
    17       = 17
   */
  var getSecondsFromExtendedTimeFormat = function (time) {
    if (time) {
      var seconds = 0;
      var timeArray = time.split(':');

      if (timeArray.length) seconds += parseInt(timeArray.pop(), 10);

      if (timeArray.length) seconds += parseInt(timeArray.pop(), 10) * secondsInAMinute;

      if (timeArray.length) seconds += parseInt(timeArray.pop(), 10) * secondsInAnHour;

      if (timeArray.length) seconds += parseInt(timeArray.pop(), 10) * secondsInADay;

      return seconds;
    }
    return 0;
  };

  /** ****************************************************************************
   * Return a string in a format as specified by formatStr like 'd:hh:mm:ss'.
   * The formatStr can containany combination of d, h, m or s or any of these
   * with two digits. The compoundTimeObject must be in the
   * format: { days:0, hours:0, mins:0, secs:0 }
   */
  var formatTime = function (formatStr, compoundTimeObject) {
    if (formatStr.indexOf('dd') >= 0) {
      formatStr = formatStr.replace('dd', twoDigits(compoundTimeObject.days));
    }
    if (formatStr.indexOf('hh') >= 0) {
      formatStr = formatStr.replace('hh', twoDigits(compoundTimeObject.hours));
    }
    if (formatStr.indexOf('mm') >= 0) {
      formatStr = formatStr.replace('mm', twoDigits(compoundTimeObject.mins));
    }
    if (formatStr.indexOf('ss') >= 0) {
      formatStr = formatStr.replace('ss', twoDigits(compoundTimeObject.secs));
    }
    if (formatStr.indexOf('d') >= 0) {
      formatStr = formatStr.replace('d', '' + compoundTimeObject.days);
    }
    if (formatStr.indexOf('h') >= 0) {
      formatStr = formatStr.replace('h', '' + compoundTimeObject.hours);
    }
    if (formatStr.indexOf('m') >= 0) {
      formatStr = formatStr.replace('m', '' + compoundTimeObject.mins);
    }
    if (formatStr.indexOf('s') >= 0) {
      formatStr = formatStr.replace('s', '' + compoundTimeObject.secs);
    }
    return formatStr;
  };

  var twoDigits = function (num) {
    return (num < 10) ? '0' + num : num.toString();
  };

  /** ****************************************************************************
   * Return an object with time span divided in compounds as in:
   * { days:0, hours:0, mins:0, secs:0 }
   */
  var getCompoundTimeObject = function (millis) {
    millis = (isNaN(millis) || millis < 0) ? 0 : millis;
    return {
      days: Math.floor(millis / (1000 * 60 * 60 * 24)),
      hours: Math.floor((millis / (1000 * 60 * 60)) % 24),
      mins: Math.floor((millis / 1000 / 60) % 60),
      secs: Math.floor((millis / 1000) % 60)
    };
  };

  /* Public interface */
  return {
    formatTime: formatTime,
    getCompoundTimeObject: getCompoundTimeObject,
    getCurrentDateTime: getCurrentDateTime,
    getServerDateTime: getServerDateTime,
    timeAsServerTimeZone: timeAsServerTimeZone,
    timeDifferenceInSeconds: timeDifferenceInSeconds,
    getSecondsFromExtendedTimeFormat: getSecondsFromExtendedTimeFormat
  };

});
