defineDs('Shared/Framework/Mithril/Scripts/Core/Component', [
  'DanskeSpil/Project/Design/Js/Global/HashChange',
  'Shared/Framework/Mithril/Scripts/Core/Mithril',
  'DanskeSpil/Domain/Authentification/Scripts/WaitForAuthSynced'
], function (HashChange, m, WaitForAuthSynced) {

  // Variables:
  var controllers = {};
  var count = 0;
  var namespaces = {};

  // Functions:
  var detect = function (name) {
    return Array.prototype.filter.call(document.querySelectorAll('[data-component="' + name + '"]'), function (item) {
      return !item.hasAttribute('data-component-count');
    });
  };

  /*

    NOTE: parameter 'dependencies' must specify those AMD-injected dependencies that have promises
    that need to be resolved before the component loads, and those dependencies must specify such
    promises through a returned property 'ready'. Also note that dependencies which rely on 'this'
    are not supported.

  */

  var initialize = function (name, dependencies, callback) {
    var $elements = detect(name);

    // If there are no elements to mount, we return early:
    if ($elements.length === 0) {
      return;
    }

    var dependencyData = m.prop({});
    var promises = [];

    callback = typeof callback === 'undefined' ? dependencies : callback;

    // Component specific dependencies
    if (typeof dependencies === 'object' && dependencies.length > 0) {
      for (var i = 0; i < dependencies.length; i++) {
        var dependency = dependencies[i];

        if (typeof dependency === 'function') {
          var result = dependency(dependencyData);

          if (result && result.ready && result.ready.then) {
            dependency = result;
          }
        }

        if (dependency.ready && dependency.ready.then) {
          promises.push(dependency.ready);
        }
      }
    }


    // DOM:
    $elements.forEach(function ($element) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      // WaitForAuthSynced promise is default enabled
      // (unless component actively Opt-out)
      var optoutAuthSync = $element.getAttribute('data-component-optout-auth-sync') === 'true'; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
      var elementPromises = !optoutAuthSync ? promises.concat(WaitForAuthSynced.ready) : promises;

      m.sync(elementPromises).then(function () {
        register($element);

        if (!namespaces[name]) {
          namespaces[name] = 1;
        } else {
          namespaces[name]++;
        }

        var internals = {
          $element: $element,
          count: count,
          mounted: {},
          namespace: name + (namespaces[name] > 0 ? '' : '-' + namespaces[name]),
          properties: {}
        };

        var settings = $element.getAttribute('data-settings') || {}; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

        if (typeof settings === 'string') {
          settings = JSON.parse(settings);
        }

        var property = function (name, value) {
          if (typeof value === 'undefined') {
            return internals.properties[name];
          } else {
            internals.properties[name] = value;
          }
        };

        var route = function (path, component) {
          var param = location.hash.split(internals.namespace + '=')[1];
          var route = param ? param.indexOf('?') ? param.split('?')[0] : param.split('&')[0] : false;

          if (typeof component === 'undefined' && typeof path === 'undefined') {
            return route === false ? '/' : route;
          } else if (typeof component === 'undefined') {
            return function () {
              var split = location.hash.slice(1).split(/\?|&/);
              var segments = [];

              for (var i = 0; i < split.length; i++) {
                var segment = split[i];

                if (segment && segment !== '') {
                  segments.push(segment);
                }
              }

              if (!route) {
                segments.push(internals.namespace + '=' + path);
              } else {
                var prefix = internals.namespace + '=';
                var index = segments.indexOf(prefix + route);

                segments[index] = prefix + path;
              }

              location.hash = segments.join('&').replace('&', '?');
            };
          } else if (typeof path !== 'undefined') {
            var componentController = internals.$element.getAttribute('data-component') + internals.$element.getAttribute('data-component-count'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

            if (!controllers[componentController]) {
              controllers[componentController] = new component.controller();
            }

            if (!internals.mounted[path]) {
              internals.mounted[path] = { component: component, instance: { controller: function () { return controllers[componentController]; }, view: component.view }, path: path };
            }

            if ((!route && path === '/') || route === path) {
              m.mount(internals.$element, internals.mounted[path].instance);
            }

            HashChange.match(function (hash) {
              var isComponent = hash.indexOf(internals.namespace + '=') > -1;

              if (!isComponent) {
                m.mount(internals.$element, internals.mounted[path].instance);
              }

              return isComponent;
            }, function (hash) {
              route = hash.split(internals.namespace + '=')[1].split('&')[0];

              if (internals.mounted[route] && internals.mounted[route].path === route) {
                m.mount(internals.$element, internals.mounted[route].instance);
              }
            });
          }
        };

        callback.bind({ $element: internals.$element })(m, route, settings, property, internals.$element, dependencyData());

        count++;
      });
    });
  };

  var register = function ($element) {
    $element.setAttribute('data-component-count', count); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
  };

  // Public functions:
  return initialize;

});
