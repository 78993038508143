defineDs('Common/Framework/ErrorHandling/Scripts/ErrorLogging', [], function () {

  var isInitialized = DS.Config.SENTRY_ENABLED && window.Sentry && !window.sitecoreEnv;

  /**
   * message <String> The message that will be the title in Sentry
   * data <Object>: The object that holds the data that should be logged in Sentry
   */
  var capture = function (message, data) {
    if (!isInitialized) {
      console.warn('Sentry is not enabled', { message: message, data: data });
      return;
    }

    try {
      window.Sentry.captureMessage(message, data);
    } catch (error) {
      console.warn('Sentry logging failed', { message: message, data: data }, error);
    }
  };


  return {
    capture: capture
  };

});
